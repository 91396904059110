import React from 'react';

import CanvasContainerConnector from './ImageSphere/CanvasContainer';
import AboutDialog from '../SideBarContent/Footer/AboutDialog';
import FeedbackDialog from '../SideBarContent/Header/FeedbackDialog';
import HelpDialog from '../SideBarContent/Header/HelpDialog';
import SessionExpiredDialog from "../Dialog/SessionExpiredDialog";
import Animator from './Animator/Animator';

import LevelAndSettings from './LevelIndicator/LevelAndSettings';

import { NotificationContainer } from "react-notifications";
import 'react-notifications/lib/notifications.css';

class MainViewContent extends React.Component {

  constructor(props) {
    super(props);
    this.canvasContainerRef = React.createRef();
  }

  render() {
    return (
      <div id='mainViewContent' style={{ width: '100%', height: '100%'}}>
        <NotificationContainer/>
        <CanvasContainerConnector
            ref={this.canvasContainerRef}
            theme={this.props.theme}/>
        <AboutDialog />
        <HelpDialog />
        <FeedbackDialog />
        <SessionExpiredDialog />
        <Animator />
        <LevelAndSettings canvasContainerRef={this.canvasContainerRef} />
      </div>
    );
  }
}
export default MainViewContent;

import React, {Component} from 'react';
import {connect} from "react-redux";
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import Palette from '@material-ui/icons/Palette';
import Search from '@material-ui/icons/Search';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDice} from '@fortawesome/free-solid-svg-icons/faDice';
import SuggestedTags from '../SuggestedTags/SuggestedTags';
import ColorFilter from '../FilterSection/ColorFilter';

import Autosuggest from 'react-autosuggest';

import {
  action_submit_search_term,
  action_upload_image,
  action_show_similar_images,
  action_similar_images,
  action_filter_color,
  action_filter_license
} from '../../../Modules/redux/actions';
import SimilarImagesBox from '../SimilarImagesBox/SimilarImagesBox';
import Collapse from '@material-ui/core/Collapse';
import withStyles from "@material-ui/core/styles/withStyles";
import getEventBus from "../../../lib/ImageSphereEventBus";
import FilterDisplay from '../FilterSection/FilterDisplay';
import LicenseFilter from '../FilterSection/LicenseFilter';
import {ccIcon} from "../../../lib/LicenseIcons"
import {resizeImage} from "../../../API/jsonAPI";
import MyPaper from "../../Styled/MyPaper";
import MyTooltip from "../../Styled/MyTooltip";

import './react-autosuggest.css';

import {fetchSuggestedKeywords} from "../../../API/jsonAPI"

const Paper = MyPaper;
const Tooltip = MyTooltip;

const toolTipDelay = 1000;

const getSuggestionValue = suggestion => suggestion;
 
// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {suggestion}
  </div>
);

class SearchInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentSearchInput: "",
      resizedImg: "",
      suggestions: []
    };

    this.search = this.search.bind(this);
    this.imgUpload = this.imgUpload.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.randomSearch = this.randomSearch.bind(this);
    window.SearchInput = this;
    this.lastRandomSearch = 0;
    this.eventBus = getEventBus();

    this.colorFilterRef = React.createRef();
    this.licenseFilterRef = React.createRef();
  }

  async imgUpload(event) {

    if (event.target.files[0] !== undefined) {
      this.props.dispatch(action_show_similar_images(true));
      this.props.dispatch(action_upload_image(event.target.files[0]));
      this.props.dispatch(action_submit_search_term(null));
      this.props.dispatch(action_filter_color([]));
      
      this.closeColorAndLicenseDialog();
      const resizedImg = await resizeImage(event.target.files[0], 40, true);
      this.setState({resizedImg: resizedImg});
      this.fileUpload.value = "";
    }
  }

  /**
   * @override
   */
  componentDidMount() {
    this.imageClickedId = this.eventBus.subscribe('ImageClicked', () => {
      this.closeColorAndLicenseDialog();
    });
  }

  /**
   * @override
   */
  componentWillUnmount() {
    this.eventBus.unsubscribe(this.imageClickedId);
  }

  onSuggestionsFetchRequested = ({ value }) => {
    if (value.length < 3) return;
    fetchSuggestedKeywords(window.sessionID, value.toLowerCase())
    .then(response => {
      this.setState({suggestions: response})})
  };
  
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  search(event, suggestedKeyword) {
    
    // enter was pressed
    if ((event.keyCode === 13) || (event.type === 'click')) {
      let keyword = this.state.currentSearchInput;

      if (suggestedKeyword) keyword = suggestedKeyword

      this.props.dispatch(action_show_similar_images(true));
      const searchTerm = new Set().add(this.props.searchTerm);

      if (this.keywordNotIncluded(this.props.searchTerm, keyword))
        searchTerm.add(keyword.toLowerCase().trim());
      this.props.dispatch(action_submit_search_term([...searchTerm].join(' ')));
      keyword = '';
      this.setState({currentSearchInput: ""});
    }
  }

  keywordNotIncluded(searchTerm, keyword) {
    return searchTerm === null || !((' ' + searchTerm + ' ').includes(' ' + keyword.toLowerCase().trim() + ' '))
  }

  randomSearch() {
    if (Date.now() - this.lastRandomSearch > 1000) {
      this.lastRandomSearch = Date.now();
      this.handleReset();

      window.CanvasContainer.randomizeViewport(); // TODO should be an action
    }
  }

  toggleColorPicker() {
    if(this.colorFilterRef.current.state.open === false)
      this.colorFilterRef.current.openDialog();
    else
      this.colorFilterRef.current.closeDialog();
    this.licenseFilterRef.current.closeDialog();
  };

  toggleLicenseFilter() {
    if(this.licenseFilterRef.current.state.open === false)
      this.licenseFilterRef.current.openDialog();
    else
      this.licenseFilterRef.current.closeDialog();
    this.colorFilterRef.current.closeDialog();
  };

  closeColorAndLicenseDialog() {
    this.colorFilterRef.current.closeDialog();
    this.licenseFilterRef.current.closeDialog();
  }

  handleReset = () => {
    this.fileUpload.value = "";
    this.props.dispatch(action_upload_image(""));
    this.props.dispatch(action_show_similar_images(false));
    this.props.dispatch(action_similar_images([]));
    this.props.dispatch(action_submit_search_term(null));
    this.props.dispatch(action_filter_color([]));
    this.props.dispatch(action_filter_license([]));
    this.setState({currentSearchInput: ""})
    this.closeColorAndLicenseDialog();
  };

  handleDeleteTag = item => () => {
    let searchTermWithoutClickedText = this.props.searchTerm.replace(item, '');
    
    //strip new searchterm and test if empty
    if (searchTermWithoutClickedText.replace(/^\s+|\s+$/g, '') === '') searchTermWithoutClickedText = null;

    //const lastItemInArray = (searchTermArray.length === 1) && (searchTermArray[0] === searchTermWithoutClickedText);
  
    if (searchTermWithoutClickedText === null && (this.props.nSelectedLicenses + this.props.nSelectedColors === 0)) {
      this.handleReset();
    } else {
      this.props.dispatch(action_submit_search_term(searchTermWithoutClickedText));
    }
  };

  onInputChange = (event, { newValue }) => {
    this.setState({
      currentSearchInput: newValue
    });
  };

  render() {

    const chipStyleSelected = {
      margin: '3px',
      height: '26px',
      backgroundColor: '#4e91ca',
      color: 'white'
    };

    let searchTerms = [];
    if (this.props.searchTerm)
      this.props.searchTerm.trim().split(' ').forEach((item) => {
        if (item) {
          searchTerms.push(
            <Chip
              style={chipStyleSelected}
              key={item}
              label={item}
              onDelete={this.handleDeleteTag(item)}/>)
        }
      });

    let searchButton;
    if (this.state.currentSearchInput.length > 0) {
      searchButton =
        <Tooltip title="Search" enterDelay={toolTipDelay}>
          <IconButton
            edge="end"
            aria-label="Search"
            className={this.props.classes.iconButton}
            onClick={this.search}>
            <Search/>
          </IconButton>
        </Tooltip>
    } else {
      searchButton =
        <Tooltip title="Random search" enterDelay={toolTipDelay}>
          <IconButton
            edge="end"
            aria-label="Search"
            className={this.props.classes.iconButton}
            onClick={this.randomSearch}>
            <FontAwesomeIcon icon={faDice}/>
          </IconButton>
        </Tooltip>
    }

    const suggesterInputProps = {
      id:"searchTermInput",
      ref:el => this.input = el,
      // defaultValue:this.props.searchTerm,
      onKeyUp:this.search,
      placeholder:"Add search term...",
      value: this.state.currentSearchInput,
      onChange: this.onInputChange
    }; 
    return (
      
      <div className={this.props.classes.rootContainer}>

        <FilterDisplay resizedImg={this.state.resizedImg} currentSearchTerms={searchTerms}
                       resetSearch={() => this.handleReset()}/>
        <Paper>
          <Box className={this.props.classes.drawerPaper + " " + this.props.classes.root}>

            {searchButton}

            <Tooltip title="Upload image" enterDelay={toolTipDelay}>
              <IconButton
                edge="end"
                aria-label="Add a photo"
                className={this.props.classes.iconButton}
                onClick={() => this.fileUpload.click()}>
                <AddAPhoto/>
              </IconButton>
            </Tooltip>
            <div className={this.props.classes.searchDivider}/>
            
            <Autosuggest
              inputProps={suggesterInputProps}
              suggestions={this.state.suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              renderSuggestion={renderSuggestion}
              getSuggestionValue={getSuggestionValue}
              onSuggestionSelected={(e, data)=>this.search(e, data.suggestionValue)}
            />
            <div style={{marginLeft: "auto", minWidth: "70px"}}>
            <Tooltip title="Search by color" enterDelay={toolTipDelay}>
              <IconButton
                edge="end"
                aria-label="Filter Color"
                className={this.props.classes.iconButton}
                onClick={() => this.toggleColorPicker()}>
                <Palette/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Filter by license" enterDelay={toolTipDelay}>
              <IconButton
                edge="end"
                aria-label="Filter License"
                className={this.props.classes.iconButton}
                onClick={() => this.toggleLicenseFilter()}>
                <svg aria-hidden="true" focusable="false"
                     role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox={ccIcon.viewBox} className={this.props.classes.licenseButton}>
                  <path fill="currentColor" d={ccIcon.path}/>
                </svg>
              </IconButton>
            </Tooltip>
            </div>

            <input
              type="file"
              accept="image/*"
              id="contained-button-file"
              hidden={true}
              onChange={(e) => this.imgUpload(e)}
              ref={(fileUpload) => {
                this.fileUpload = fileUpload;
              }}/>
          </Box>


          <ColorFilter ref={this.colorFilterRef} />
          <LicenseFilter ref={this.licenseFilterRef} />

          <hr/>
          <SuggestedTags />
          <Collapse in={this.props.showSimilarImages || searchTerms.length > 0}>
            <SimilarImagesBox/>
          </Collapse>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    searchTerm: state.search.searchTerm,
    nSelectedColors: state.filter.color.length,
    nSelectedLicenses: state.filter.licenses.length,
    uploaded_img: state.search.uploaded_img,
    showSimilarImages: state.search.showSimilarImages
  }
}

const styles = () => ({
  root: {
    padding: '2px',
    display: 'flex',
    flexDirection: 'row',
  },
  input: {
    flex: 1,
    "& > input": {
      padding: 0,
      marginTop: "10px",
      marginLeft: "10px",
      fontSize: "16px"
    }
  },
  iconButton: {
    padding: 10,
    width: "45px", 
    color: "#006499"
  },
  rootContainer: {
    padding: "10px 0 0 0"
  },
  searchDivider: {
    width: "2px",
    margin: "4px 0 0 13px",
    backgroundColor: "#ddd",
  },
  licenseButton: {
    width: '20px',
    height: '20px',
  }
});


export default connect(mapStateToProps)(withStyles(styles)(SearchInput));

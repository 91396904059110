import React from 'react'
import {connect} from "react-redux";
import { initWorldMap, initViewPort, jumpToId, jumpToFile, move, similarImageUpload, searchKeyword, filter, random, searchImageId} from '../../../API/jsonAPI';

import { action_level_reset, action_similar_images, action_show_similar_images, action_level_transform, action_level, action_filter_results, action_search_tags, action_filter_color_processing} from '../../../Modules/redux/actions';

import { WORLD_MAP_HEIGHT, WORLD_MAP_WIDTH } from '../../../config'
import Canvas from './Canvas'
import DataPreprocessor from './DataPreprocessor'
import CircularProgress from '@material-ui/core/CircularProgress';
import { NotificationManager } from 'react-notifications';

const levelToTransformationPropertyMap = {
  0: {
    zoomFactor: 1.5,
    shapeValue: 0
  },
  1: {
    zoomFactor: 1,
    shapeValue: 20
  },
  2: {
    zoomFactor: 1,
    shapeValue: 40
  },
  3: {
    zoomFactor: 1,
    shapeValue: 60
  },
  4: {
    zoomFactor: 1,
    shapeValue: 80
  },
  5: {
    zoomFactor: 1,
    shapeValue: 90
  },
  6: {
    zoomFactor: 1,
    shapeValue: 100
  },
};

/**
* Prepares contents to be drawm
*/
export class CanvasContainer extends React.Component {

  constructor(props){
    super(props);

    window.CanvasContainer = this; //Make this instance globally available

    this.state = {
      imageArray2D: [],
      newestMoveRequestTime: 0,
      requesting: false,
      canvasMoving: false
    };
    this.message = "###";
    this.handleDrag = this.handleDrag.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.transformSphere = this.transformSphere.bind(this);
    window.lastSuccessfulFilter = props.filter
  }

  async initWorldMap(){
    await initWorldMap(window.sessionID, WORLD_MAP_HEIGHT, WORLD_MAP_WIDTH);
    this.lastLevel = this.props.level;
  }

  async initViewPort(){
    const data = await initViewPort(window.sessionID, this.props.gridSideLength, this.props.gridSideLength);
    if(data) {
      this.props.dispatch(action_level(data["level"]));
      this.preprocessData(data);
    }
  }

  /**
   * Jump to an random image
   *
   * @return {Promise<void>}
   */
  async randomizeViewport() {
    if(window.canvas && window.canvas.isBusy) return;

    const data = await random(window.sessionID, this.props.level);
    if(data){
      const centralImageIndex = Math.floor(this.props.gridSideLength / 2);
      this.moveToImage(data.id, this.props.level, centralImageIndex, centralImageIndex);
    }
  }

  async move(x, y, directionX, directionY, skimming){
  
    //console.log("move " + x + " " + y)
    const currentMoveRequestTime = new Date().getTime();
    this.setState({
      latestMoveRequest: currentMoveRequestTime,
      canvasMoving: true
    });
    // const oldCanvasX = window.canvas.xm;
    // const oldCanvasY = window.canvas.ym;
    const data = await move(window.sessionID, x, y, directionX, directionY);
    
    // let transformX = Math.trunc(window.canvas.xm) - Math.trunc(oldCanvasX);
    // let transformY = Math.trunc(window.canvas.ym) - Math.trunc(oldCanvasY);
    //debugger;#
    
    const isLatestResponse = currentMoveRequestTime === this.state.latestMoveRequest;
    // if(skimming){
    //   transformX = 0;
    //   transformY = 0;
    // }
    
    if(isLatestResponse){
      this.setState({
        canvasMoving: false
      })
    }
    // && (currentMoveRequestTime === this.state.latestMoveRequest)
    if(data && isLatestResponse) 
      this.preprocessData(data);
  }


  /**
   * Stops all image loading processes of very element in the imageArray2D.
   */
  cleanImageQueue() {
    this.state.imageArray2D.map(dim1 => {
      return dim1.map((item)=>{
        if(item && !item.loaded && item !== "preview") {
          item.src = "";
        }
        return item
      })
    });
  }

  preprocessData(data){
    if(data){
      this.cleanImageQueue();
      this.extractKeywords(data.keyword_weighting);
      const imageArray2D = new DataPreprocessor().startProcessingPipeline(data.map, this.props.gridSideLength);
      //debugger;
      this.setState({ "imageArray2D": imageArray2D });
      this.canvasHasBeenUpdated();
    }
  }

  extractKeywords(keyword_weighting) {
    if(!keyword_weighting) return;

    let keywords = [];
    for(let i = 0; i< 20; i++){
      if(keyword_weighting[i])
        keywords.push(keyword_weighting[i].word)
    }
    this.props.dispatch(action_search_tags(keywords))

  }

  newImageUploaded(oldProps, newProps) {
    return newProps.search.uploaded_img !== oldProps.search.uploaded_img && newProps.search.uploaded_img !== '';
  }

  filterOrSearchChanged(oldProps, newProps) {
    return JSON.stringify(oldProps.search.searchTerm) !== JSON.stringify(newProps.search.searchTerm) ||
    JSON.stringify(oldProps.filter.licenses) !== JSON.stringify(newProps.filter.licenses) ||
    JSON.stringify(oldProps.filter.color) !== JSON.stringify(newProps.filter.color)
  }

  findSimilarImages(uploadedImg) {
    if(uploadedImg){
      this.searchSimiliarImage(uploadedImg);
    }
  }

  /**
   *
   * @param props
   * @param {boolean} resetSearch
   * @param {boolean} resetFilter
   * @return {Promise<void>}
   */
  async filterChange(props, resetSearch = false, resetFilter = false) {

    // license and keyword search
    if (!resetFilter && props.filter.licenses.length !== 0 && props.filter.licenses.length !== this.props.filter.licenses.length){
      // indicate a color search
      this.props.dispatch(action_filter_color_processing(true));
      filter(window.sessionID, resetFilter).then((filterLevelResults) => {
        this.handleFilterLevelResults(filterLevelResults);
        this.handleLevel();
        this.licenseSearch(props.search.searchTerm, props.filter.licenses);
      });

    // pure keyword search
    } else if (!resetFilter && (props.search.searchTerm !== null || props.filter.color.length !== 0)){
      this.props.dispatch(action_filter_color_processing(true));
      this.keywordSearch(props.search.searchTerm)

    // image search
    } else if(!resetFilter && props.search.uploaded_img !== ""){
      this.findSimilarImages(props.search.uploaded_img)
    }
  }

  handleFilterLevelResults(filterLevelResults) {
    if(filterLevelResults) this.props.dispatch(action_filter_results(filterLevelResults));
  }

  async handleLevel() {

    if(this.props.filterLevelResults[this.props.level] > window.levelResultsThreshold) return;

    let queryLevel = this.props.level;
    while(queryLevel >= 0){
      if(this.props.filterLevelResults[queryLevel] > window.levelResultsThreshold || queryLevel === 0){
        const image = window.canvas.getClosestImageToCenter();
        this.handleZoom(image, queryLevel, true);
        break;
      }
      queryLevel--; 
    }
  }


  async licenseSearch(searchTerm) {

    // keyword and color search
    if (searchTerm !== null || this.props.filter.color.length !== 0){
      this.props.dispatch(action_show_similar_images(true));
      this.searchByKeyword(searchTerm, false);

    // upload search
    } else if(this.props.search.uploaded_img !== ""){
      this.findSimilarImages(this.props.search.uploaded_img)

    // only filters are active
    } else {

      // jumpToId to the center image, after applying the filter
      const image = window.canvas.getClosestImageToCenter();
      this.moveToImage(image.id, this.props.level, parseInt(image.getAttribute("posx")), parseInt(image.getAttribute("posy")));
      this.props.dispatch(action_filter_color_processing(false));
    }
  }

  /**
   * Pure keyword search
   *
   * @param searchTerm
   */
  keywordSearch(searchTerm) {
    this.props.dispatch(action_show_similar_images(true));
    this.searchByKeyword(searchTerm);
  }

  async searchSimiliarImage(imgFile) {
    
    this.props.dispatch(action_filter_color_processing(true));
    await similarImageUpload(window.sessionID, imgFile, 6).then(async (similarImages) => {

      if(similarImages && similarImages.length > 0) this.handleSearch(similarImages);
      else {
          NotificationManager.warning('No search results!', '', 3000,()=>{},true);
      }
      this.props.dispatch(action_filter_color_processing(false));
    })
  }

  async searchByKeyword(keyword, notify=true) {

    this.props.dispatch(action_filter_color_processing(true));
    await searchKeyword(window.sessionID, keyword, 9).then( async (similarImages) => {

      if(similarImages && similarImages.length > 0) this.handleSearch(similarImages);
      else if(notify){
          NotificationManager.warning('No search results!', '', 3000,()=>{},true);
      }
    });
    this.props.dispatch(action_filter_color_processing(false));
    
  }

  async searchByImage(imageId, notify=true, skipAnimation=true) {
    this.props.dispatch(action_show_similar_images(true));
    this.props.dispatch(action_filter_color_processing(true));
    await searchImageId(window.sessionID, imageId, 9).then( async (similarImages) => {

      if(similarImages && similarImages.length > 0) {
        similarImages[0].skipAnimation = skipAnimation;
        this.handleSearch(similarImages);
      }else if(notify){
          NotificationManager.warning('No search results!', '', 3000,()=>{},true);
      }
    });
    this.props.dispatch(action_filter_color_processing(false));
    
  }

  async handleSearch(similarImages) {
    // window.Animator.showOverlayCanvas();
    const mostSimilarImage = similarImages[0];
    this.showSimilarImages(similarImages);
    if(mostSimilarImage)
      this.props.dispatch(action_level_reset());
  }

  showSimilarImages(similarImages) {
    window.canvas.hideCenterImageButton();
    this.props.dispatch(action_similar_images(similarImages));
  }

  /**
   * Set a new central image given by the filename or id
   *
   * @param {string} filename used to update the URL hash
   * @param {number} level
   * @param {number} [id]
   * @return {Promise<void>}
   */
  async updateCentralImage(filename, level = 0, id){

    // update the hash of the URL to create a unique link to this image
    if(window.location.hash.slice(1) !==  filename)
      window.history.pushState({hash: filename}, "", "#"+filename);

    if(level === 0)
      this.props.dispatch(action_level_reset());

    // move the viewport
    const {posX, posY} = window.canvas.getCentralImagePosition();
    if(id) {
      return this.moveToImage(id, level, posX, posY);
    } else {
      return new Promise((resolve, reject) => {
        jumpToFile(window.sessionID, filename, posX, posY, level)
          .then((data)=>{
              if(String(data).includes("Error")){
                reject()
              }else{
                if(data)
                  this.preprocessData(data);
                resolve()
              }
            }
          )
      });
    }
  }


  /**
   * Place the image given by the id at the given position on the canvas
   * and place similar images of the given level around it
   *
   * @param {number} id
   * @param {number} level
   * @param {number} posX
   * @param {number} posY
   * @return {Promise<void>}
   */
  async moveToImage(id, level = 0, posX = 9, posY = 9){
    if(level === 0)
      this.props.dispatch(action_level_reset());

    return new Promise((resolve, reject) => {
      jumpToId(window.sessionID, id, posX, posY, level)
        .then((data)=>{
            if(String(data).includes("Error")){
              reject()
            }else{
              if(data)
                this.preprocessData(data);
              resolve()
            }
          }
        )
    });
  }

  /**
   * Event handler
   *
   * @param xDirection
   * @param yDirection
   * @param skimming
   * @return {Promise<void>}
   */
  async handleDrag(xDirection, yDirection, skimming=false){
    //console.log("handleDrag", xDirection, yDirection) 
    const x = -xDirection;
    const y = -yDirection;
    
    if (Math.round(x) === 0 && Math.round(y) === 0)
      return;

    const dist = Math.sqrt(x*x + y*y);
    if(this.state.imageArray2D.length > 0)
      await this.move(Math.round(x), Math.round(y), x/dist , y/dist, skimming);  // TODO await weg?
  }


  async handleZoom(targetImage, level, skipAnimation) {
    if(window.canvas.isBusy || level > 5 || level < 0) return;
    window.canvas.hideCenterImageButton();
    this.props.dispatch(action_level(level));
    window.canvas.isBusy = true;
    if(!skipAnimation) this.showZoomAnimation(targetImage, level);
    return await this.moveToImage(targetImage.id, level, targetImage.getAttribute("posx"), targetImage.getAttribute("posy"));
  }

  showZoomAnimation(targetImage, level){
    const top  = parseInt(targetImage.getAttribute('coordy'));
    const left = parseInt(targetImage.getAttribute('coordx'));
    const width = parseInt(targetImage.getAttribute('xsize'));
    const height = parseInt(targetImage.getAttribute('ysize'));

    const zoomIn = this.lastLevel > level;
    window.Animator.zoom(targetImage.cloneNode(true), {top, left, width, height}, zoomIn);
    this.lastLevel = level;
  }

  canvasHasBeenUpdated(){ 
    
    this.manageSphereTransformation();
    if(window.Animator) window.Animator.canvasHasBeenUpdated();
    window.canvas.drawImagesOnCanvas();
    
    // setTimeout(() => {
    //   //Draw images AGAIN after canvas has been updated. --> Images will be drawn from outside to inside --> central images will have higher zIndex
    //   
    // }, 350);
  }

  manageSphereTransformation(){
    if(this.props.autoTransform){
      this.transformSphere(this.props.level);
    }
  }

  transformSphere(level){
    this.props.dispatch(action_level_transform({
      zoomFactor: levelToTransformationPropertyMap[level].zoomFactor,
      shapeValue: levelToTransformationPropertyMap[level].shapeValue
    }));
  }







  /**
   * Lifecycle methods
   * @override
   */
  async componentDidMount() {
    await this.initWorldMap();
    await this.initViewPort();
  }

  /**
   * TODO inhalt sollte in die componentDidUpdate methode
   *
   * @override
   * @param nextProps
   * @param nextState
   */
  componentWillReceiveProps(nextProps, nextState) {
    if(this.filterOrSearchChanged(this.props, nextProps) || this.newImageUploaded(this.props, nextProps))
      this.filterChange(nextProps);
  }


  /**
   * @override
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  async componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.gridSideLength !== this.props.gridSideLength){
      const data = await initViewPort(window.sessionID, this.props.gridSideLength, this.props.gridSideLength);
      if(data){
        // const centralImageIndex = Math.floor(this.props.gridSideLength / 2);
        // const centraImage = data.map.tiles.filter(element => element.x === centralImageIndex && element.y === centralImageIndex)[0]
        //this.moveToImage(centraImage.content.id, this.props.level, centralImageIndex, centralImageIndex);
        this.preprocessData(data)
      }
    }
      
  }


  render(){
  
    if(this.state.imageArray2D.length !== 0) {
    return(
      <div id="CanvasContainer" style={{width: '100%', height: '100%', background: 'rgb(' + this.props.backgroundColor + ',' + this.props.backgroundColor + ',' + this.props.backgroundColor + ')'}}>
        <Canvas num={this.props.gridSideLength}
          imageArray2D={this.state.imageArray2D}
          handleDrag={this.handleDrag}
          handleZoom={this.handleZoom}
          theme={this.props.theme}
          isMoving={this.state.canvasMoving}
          />
          
      </div>
    );
  } else {
    return <div style={{display: 'flex', height: '100%',  alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div>
  }
  }
}

function mapStateToProps(state){
    return {
        gridSideLength: state.settings.gridSideLength,
        autoTransform: state.settings.autoTransform,
        level: state.level.level,
        shapeValue: state.settings.shapeValue,
        zoomFactor: state.settings.zoomFactor,
        filter: state.filter,
        search: state.search,
        showSimilarImages: state.search.similarImages,
        filterLevelResults: state.filter.filterLevelResults,
        backgroundColor: state.settings.backgroundColor,
    }
}

export default connect(mapStateToProps, null, null, {forwardRef : true})(CanvasContainer);
import React from "react";
import {connect} from "react-redux";
import {action_filter_results} from "../../../Modules/redux/actions"
class LevelIndicator extends React.Component {

  constructor(props) {
    super(props);
    this.onMouseUpHandler = this.onMouseUpHandler.bind(this);
    //this.margin = 15;
    //this.perspectiveFactor = 3.5;
    //this.vSpace = 5;
    //this.m = 0.35;
    this.marginVer = 5;
    this.marginHor = 15;
    this.m = 0.5;
    this.aspectRatio = 0.25;
    this.shear = -0.5;
    window.levelResultsThreshold = 300;
    this.numberOfLevels = 6;
  }

  /**
   * @override
   */
  componentDidMount() {
    this.canvasContext = this.canvas.getContext("2d");
    this.width = this.canvas.width;
    this.height = this.canvas.height;
    this.draw();
    const initialFilteredLevelState = []

    for (let i = 0; i < this.numberOfLevels; i++) {
      initialFilteredLevelState.push(window.levelResultsThreshold +1)
    }
    this.props.dispatch(action_filter_results(initialFilteredLevelState));
  }

  /**
   * @override
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.canvasContext = this.canvas.getContext("2d");
    this.width = this.canvas.width;
    this.height = this.canvas.height;
    this.draw();
  }

  isInside(point, vs) {
   
    const x = point[0], y = point[1];
    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      const xi = vs[i][0], yi = vs[i][1];
      const xj = vs[j][0], yj = vs[j][1];

      const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) 
        inside = !inside;
    }
    return inside;
  }

  getLevelAt(posX, posY) {
    
    const point = [posX, posY];   

    const layerHeight = (this.height - 2*this.marginVer) / (this.numberOfLevels+2);
    const x1 = this.width/2;
    const x2 = this.width - this.marginHor;
    const x4 = this.marginHor;
    const y  = this.height - this.marginVer - layerHeight;

    let layer = -1;
    for (let i = 0; i < this.numberOfLevels; i++) {
        const deltaX = this.m * layerHeight * i;
        const deltaY = this.aspectRatio * (this.width/2-deltaX);
        const offsetY = y - layerHeight * i;
        
        const polygon = [ 
            [x1 + this.shear*deltaY, offsetY - deltaY],
            [x2 - deltaX,            offsetY + this.shear*deltaY],
            [x1 - this.shear*deltaY, offsetY + deltaY], 
            [x4 + deltaX,            offsetY - this.shear*deltaY]
          ]; 
        
        if ( this.isInside(point, polygon) )
          layer = i;
    }
    //alert(layer);
    return layer;
  }


  draw() {
    this.canvasContext.clearRect(0, 0, this.width, this.height);
    const layerHeight = (this.height - 2*this.marginVer) / (this.numberOfLevels+2);
    const x1 = this.width/2;
    const x2 = this.width - this.marginHor;
    const x4 = this.marginHor;
    const y  = this.height - this.marginVer - layerHeight;
    
    this.canvasContext.strokeStyle = "#333333";
    this.canvasContext.globalAlpha = 1.0;
    
    for (let i = 0; i < this.numberOfLevels; i++) {
      if(this.props.filterLevelResults.length > 0 && this.props.filterLevelResults[i] < window.levelResultsThreshold && i !== 0){
        this.canvasContext.fillStyle = '#cccccc';
        // this.context.globalAlpha = 0.26;
      }
       else if (i === this.props.level) 
            this.canvasContext.fillStyle = '#006499';
        else 
            this.canvasContext.fillStyle = '#4e91ca';
        
        let deltaX = this.m * layerHeight * i;
        let deltaY = this.aspectRatio * (this.width/2-deltaX);
        let offsetY = y - layerHeight * i;
        
        this.canvasContext.restore();
        this.canvasContext.beginPath();
        this.canvasContext.moveTo(x1 + this.shear*deltaY, offsetY - deltaY);
        this.canvasContext.lineTo(x2 - deltaX,            offsetY + this.shear*deltaY);
        this.canvasContext.lineTo(x1 - this.shear*deltaY, offsetY + deltaY);
        this.canvasContext.lineTo(x4 + deltaX,            offsetY - this.shear*deltaY);
        this.canvasContext.closePath();
        this.canvasContext.fill();
        this.canvasContext.stroke();
    }

    /*
    this.context.strokeStyle = "#333333";
    this.context.globalAlpha = 1.0;
    const deltaLastLevel = (this.m * (layerHeight + this.vSpace) * (this.numberOfLevels - 1));
    this.context.beginPath();
    this.context.moveTo(x2-this.vSpace, y1);
    this.context.lineTo(x2 - deltaLastLevel, y1 - (layerHeight + this.vSpace) * (this.numberOfLevels - 1));
    this.context.stroke();
    
    this.context.beginPath();
    this.context.moveTo(x3, y2);
    this.context.lineTo(x3, y2 - (layerHeight + this.vSpace) * (this.numberOfLevels-1) + layerHeight*heightFactor*heightFactor);
    this.context.stroke();
    
    this.context.beginPath();
    this.context.moveTo(x4, y1);
    this.context.lineTo(x4 + deltaLastLevel, y1 - (layerHeight + this.vSpace) * (this.numberOfLevels - 1));
    this.context.stroke();
    */
}

  onMouseUpHandler(e){
    const boundingBox = document.getElementById("levelPyramideCanvas").getBoundingClientRect();
    const level = this.getLevelAt(e.clientX - boundingBox.left, e.clientY - boundingBox.top);
    if(level === this.props.level)
      return;
    if(this.props.filterLevelResults.length > 0 && this.props.filterLevelResults[level] < window.levelResultsThreshold){
      return;
    }
    this.props.zoom(level);
  }

  render() {
    //const parent = document.getElementById("pyramidContainer")
    return (
      <canvas
        style={{height: '100%', width:'100%'}}
        height={this.props.canvasHeight}
        width={this.props.canvasWidth}
        id='levelPyramideCanvas'
        ref={ref => this.canvas = ref}
        onMouseUp={this.onMouseUpHandler}
        // onMouseDown={this.onMouseDownHandler}
        // onMouseMove={this.mouseMoveHandler}
        // onDoubleClick={this._handleDoubleClickItem}
      />
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    level: state.level.level,
    filterLevelResults: state.filter.filterLevelResults,
    canvasHeight: ownProps.canvasHeight,
    canvasWidth: ownProps.canvasWidth,
  }
}

export default connect(mapStateToProps)(LevelIndicator);

import React from 'react';
import { connect } from "react-redux";
import { default as TwitterPicker } from 'react-color/lib/components/twitter/Twitter';

import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import { action_filter_color} from '../../../Modules/redux/actions'
import withStyles from "@material-ui/core/styles/withStyles";

const colorTable = ["#aadcfb", "#9c99fb", "#c487ec", "#e6a1ac", "#f1b8a0", "#f5cc9e", "#fbe9ac", "#ebeeaf", "#cce5aa", "#b5e5bd",
                    "#4f93cd", "#3d44c1", "#792c9e", "#c22d2f", "#dc5c19", "#e19025", "#f0cd42", "#cace49", "#8ab44b", "#62aa68",
                    "#2d577a", "#242a79", "#491b60", "#7a1613", "#86330d", "#885416", "#9e8421", "#828329", "#5a7331", "#3f6743",
                    "#000000", "#333333", "#666666", "#999999", "#cccccc", "#ffffff"];

class ColorFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };

        this.handleColorChange = this.handleColorChange.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    /**
     * Add a new color
     *
     * @param color
     */
    handleColorChange = (color) => {
        if (!this.props.color.includes(color.hex)) {
            const colors = this.props.color.slice();
            colors.push(color.hex);
            this.props.dispatch(action_filter_color(colors));
        }
    };

    openDialog() {
        this.setState({
            open: true,
        });
    }

    closeDialog() {
        this.setState({
            open: false,
          });
    }

    render() {
        return (
            <div className={this.props.classes.rootContainer}>
                <Collapse in={this.state.open}>
                    <IconButton
                          edge="end"
                          aria-label="Close color filter"
                          className={this.props.classes.iconButton}
                          onClick={this.closeDialog}>
                          <ArrowDropUp style={{fontSize: "2em"}}/>
                      </IconButton>
                    <div>
                        <TwitterPicker
                            width={"100%"}
                            triangle={"hide"}
                            color={this.props.color[this.props.color.length - 1]}
                            colors={colorTable}
                            onChangeComplete={this.handleColorChange}
                            className={this.props.classes.blockPicker} />
                    </div>
                </Collapse>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        color: state.filter.color
    }
}

const styles = ({
    blockPicker: {
        boxShadow: "none !important",
        "& > div > span > div": {
            width: "29px !important",
            height: "29px !important",
            border: "solid 1px #bbb !important",
            borderRadius: "50% !important"
        },

        "& > div": {
            padding: "0 5px 10px 15px !important",
        }

    },
    rootContainer:{
        margin: '5px',
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 4px"
    },
    colorPoint:{
        '& div:first-child': {
            display: "none"
        },
        '&:hover': {
            border: "3px solid black",
            '& div:first-child': {
                display: "table",
                borderRadius: "10px",
                lineHeight: "17px",
                color: "black",
                backgroundColor: "rgba(255,255,255,0.5)",
            }
        }
    },
    closeButton:{
        float:'right',
        marginRight:'-20px' 
    },
    iconButton:{
        // "& > span > svg":{
        //     fontSize: "30px",
        // },
        padding: "0px",
        
        left: "83%"
    }
});

// https://github.com/reduxjs/react-redux/issues/914
// https://github.com/pgarciacamou/react-context-consumer-hoc/pull/20
// https://react-redux.js.org/api/connect#options-object
export default connect(mapStateToProps, null, null,{forwardRef : true})(withStyles(styles)(ColorFilter));

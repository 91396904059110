import { combineReducers } from 'redux';
import { actionTypes } from './actions';

const initalSearchState = {
  searchTerm: null,
  similarImages: [],
  uploaded_img: '',
  showSimilarImages: false,
  sessionExpired: false,
  suggestedTags: [],
};

function searchReducer(state = initalSearchState, action) {
  switch (action.type) {
    case actionTypes.SUBMIT_SEARCH_TERM:
      return Object.assign({}, state, {
        searchTerm: action.payload.searchTerm
      });
    case actionTypes.SIMILAR_IMAGES:
      return Object.assign({}, state, {
        similarImages: action.payload.similarImages
      });
    case actionTypes.SHOW_SIMILAR_IMAGES:
      return Object.assign({}, state, {
        showSimilarImages: action.payload.showSimilarImages
      });
    case actionTypes.UPLOADED_IMAGE:
      return Object.assign({}, state, {
        uploaded_img: action.payload.uploaded_img
      });
    case actionTypes.SEARCH_TAGS:
      return Object.assign({}, state, {
        suggestedTags: action.payload.suggestedTags
      });
    case actionTypes.SESSION_EXPIRED:
      return Object.assign({}, state, {
        sessionExpired: action.payload.isExpired
      });
    default:
      return state
  }
}
const backgroundColor = window.localStorage.getItem("wikimediaImageSphereBackgroundColor");
const shapeValue = window.localStorage.getItem("wikimediaImageSphereShapeValue");
const gridSideLength = parseInt(window.localStorage.getItem("wikimediaImageSphereGridSideLength"));

const initialSettingsState = {
  shapeValue: shapeValue ? shapeValue : 100,
  zoomFactor: 1,
  gridSideLength: gridSideLength ? gridSideLength : 19,
  imageSpace: 1,
  backgroundColor: backgroundColor ? backgroundColor : 220
};


function settingsReducer(state = initialSettingsState, action) {

  switch (action.type) {
    case actionTypes.SHAPE_VALUE:
      return Object.assign({}, state, {
        shapeValue: action.payload.shapeValue
      });
    case actionTypes.LEVEL_TRANSFORM:
      return Object.assign({}, state, {
        zoomFactor: action.payload.config.zoomFactor,
        shapeValue: action.payload.config.shapeValue,
      });
    case actionTypes.IMAGE_SPACE:
      return Object.assign({}, state, {
        imageSpace: action.payload.imageSpace
      });
    case actionTypes.GRID_SIDE_LENGTH:
      return Object.assign({}, state, {
        gridSideLength: action.payload.gridSideLength
      });
    case actionTypes.BACKGROUND_COLOR:
      return Object.assign({}, state, {
        backgroundColor: action.payload.backgroundColor
      });
    default:
      return state
  }
}

const initialLevelState = {
  level: 2
};

function levelReducer(state = initialLevelState, action) {
  function getNextLevel(action, state) {
    if (action === actionTypes.LEVEL_ZOOM_OUT) {
      if (state.level < 6) return state.level + 1;
      else return state.level;
    }
    if (action === actionTypes.LEVEL_ZOOM_IN) {
      if (state.level > 0) return state.level - 1;
      else return state.level;
    }
  }

  const nextLevel = getNextLevel(action.type, state);
  switch (action.type) {
    case actionTypes.LEVEL_ZOOM_OUT:
      return Object.assign({}, state, {
        level: nextLevel
      });
    case actionTypes.LEVEL_ZOOM_IN:
      return Object.assign({}, state, {
        level: nextLevel
      });
    case actionTypes.LEVEL_RESET:
      return Object.assign({}, state, {
        level: 0
      });
    case actionTypes.LEVEL:
      return Object.assign({}, state, {
        level: action.payload.level
      });
    default:
      return state
  }
}

const initalFilterState = {
  color: [],
  colorIsProcessing: false,
  licenseIsProcessing: false,
  licenses: [],
  filterLevelResults:[]
};

function filterReducer(state = initalFilterState, action) {
  switch (action.type) {
    case actionTypes.FILTER_COLOR:
      return Object.assign({}, state, {
        color: action.payload.colorFilter
      });
    case actionTypes.FILTER_COLOR_PROCESSING:
      return Object.assign({}, state, {
        colorIsProcessing: action.payload.colorIsProcessing
      });
    case actionTypes.FILTER_LICENSE:
        return Object.assign({}, state, {
          licenses: action.payload.licenseFilter
        });
    case actionTypes.FILTER_RESULTS:
      return Object.assign({}, state, {
        filterLevelResults: action.payload.filterResults
      });
    default:
      return state
  }
}

export default combineReducers({
  search: searchReducer,
  filter: filterReducer,
  settings: settingsReducer,
  level: levelReducer
});

import store from '../Modules/redux/store.js'

export default class JSONGenerator {

  getSearchObject(count = 6){
    return {
      keywords: store.getState().search.searchTerm ? store.getState().search.searchTerm.split(" ") : [],
      colors: store.getState().filter.color.map(item => item.slice(1)), // remove # in front of every color
      count 
    }
  }

  getLicenseFilterObject(){
    const obj = {};
    const arr = ["cc", "by", "sa", "pd", "other"];
    arr.forEach((license) => {
      const searchTerm = license === "other" ? "ot" : license;
      obj[license] = store.getState().filter.licenses.includes(searchTerm) ? 0 : 1
    });

    return obj;
  }
}
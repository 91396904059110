import Tooltip from '@material-ui/core/Tooltip';
import withStyles from "@material-ui/core/styles/withStyles";

const MyTooltip = withStyles({
    tooltip: {
        fontSize: "12px"
    },
})(Tooltip);

export default MyTooltip;



import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Help from '@material-ui/icons/Help';
import Feedback from '@material-ui/icons/Feedback';
import IconButton from '@material-ui/core/IconButton';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dark: false,
            settings: false
        };
    }

    feedback(){
        window.FeedbackDialog.handleClickOpen();
    }

    help(){
        window.HelpDialog.handleClickOpen();
    }

    reloadPage(){
      window.location.reload(true);
    }

    render() {
        return (
          <div className={this.props.classes.header}>
            <img alt="wikiview logo" src="wikiview_logo.svg" style={{width: "45px", height: "45px", marginRight: '20px'}}/>
            <div className={this.props.classes.linksContainer}>
              <a className={this.props.classes.nameText} onClick={this.reloadPage} href={window.location.origin}>wikiview.net</a>
              <div style={{marginTop: "-14px", marginRight: "-27px"}}> 
                <a target="_blank" rel="noopener noreferrer" className={this.props.classes.additionalNameText} href="https://visual-computing.com">by visual-computing.com</a>
              </div>
            </div>
            <div className={this.props.classes.headerContainer}>

              <IconButton
                edge="end"
                aria-label="Info"
                className={this.props.classes.headerIcon}
                onClick={this.help}
              >
                <Help/>
              </IconButton>

              <IconButton
                edge="end"
                aria-label="Help"
                className={this.props.classes.headerIcon}
                onClick={this.feedback}
              >
                <Feedback/>
              </IconButton>

            </div>
          </div>

        );
    }
}

const styles = ({
  headerIcon: {
    zIndex: 2,
    color: "#fff",
    backgroundColor: "4e91ca",
    "& > span > svg": {
      fontSize: "1.5rem",
    },
  },
  headerContainer: {
    marginLeft: 'auto', 
    marginRight: "10px"
  },
  header: {
    display: 'flex',
    background:"#4e91ca",
    width: "100%",
    height: "55px",
    minHeight: "55px",
    textAlign : "center",
    verticalAlign: 'middle',
    padding: "5px",
  },
  nameText: {
    color:"#fff !important",
    background:"#4e91ca",
    fontSize: "30px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none"
    },
  },
  additionalNameText:{
    color:"#d7d7d7 !important",
    background:"#4e91ca",
    fontSize: "12px",
    cursor: "pointer",
    "&:hover": {
      color:"#fff !important",
    },
  },
  linksContainer: {
    marginTop:"-5px",
    marginLeft: "auto",
    marginRight: "auto",
  }
});

export default withStyles(styles)(Header);

import Paper from '@material-ui/core/Paper';
import withStyles from "@material-ui/core/styles/withStyles";


const MyPaper = withStyles({
    root: {
        boxShadow: '0px 1px 3px 1px rgba(87,87,87,0.8), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(87,87,87,0.8)',
        padding: "1px"
    },
})(Paper);

export default MyPaper;



export const WORLD_MAP_WIDTH = 300;
export const WORLD_MAP_HEIGHT = 300;

export const MIN_LOCAL_MAP_SIZE = 5;
export const MAX_LOCAL_MAP_SIZE = 29;

export const IMAGE_BASE_URL = 'https://wikiview.net/image';
export const GRAFFLE_DOMAIN = 'https://wikiview.net/api';


//export const IMAGE_BASE_URL = 'http://visual-computing.lab/wikiview/image';
//export const GRAFFLE_DOMAIN = 'http://visual-computing.lab/wikiview/api';


import md5 from 'md5';

export async function extractWikimediaInformation(imgName) {
  const response = await getResponse(imgName);
  return await extractMetaInfoJSon(response)
}

/*
ProxyPreamble is needed because of cors errors
*/
function assembleAPIUrl(imgName){
  if(imgName)
    imgName = imgName.normalize('NFC');
  // https://en.wikipedia.org/wiki/Special:ApiSandbox#action=query&format=json&origin=*&prop=imageinfo&titles=Image%3ACoding_da_Vinci_Kick_Off_(25_%26_26-04-2015)_117.jpg&iiprop=extmetadata
  return `https://commons.wikimedia.org/w/api.php?origin=*&action=query&titles=Image:${imgName}&prop=imageinfo&iiprop=extmetadata&format=json`
}

async function getResponse(imgName) {
  const url =  assembleAPIUrl(imgName);
  return fetch(url)
}

/*
difficult extraction of metaInformation of response
inspect response and underlying objects for understanding
in objectOfInterest is also the file name if needed
*/
async function extractMetaInfoJSon(response) {
  const responseObject = await response.json();
  const objectOfInterestPacked = responseObject.query.pages;
  const keys = Object.keys(objectOfInterestPacked);
  const objectOfInterest = objectOfInterestPacked[keys[0]];

  const metaInfoArray = objectOfInterest['imageinfo'];
  if (metaInfoArray != null) {
      const metaInfoJson = metaInfoArray[0]['extmetadata'];
      return metaInfoJson
  }
  return null
}

function assembleMD5Part(imgName) {
    const md5_src = md5(imgName);
    const first_dir = md5_src.charAt(0);
    const second_dir = first_dir + md5_src.charAt(1);
    return first_dir + '/'+ second_dir + '/'
}

 export function getUrlByName(img, compressed){
  try {
    const encodedUri = encodeURI(img.name);

    const baseUrl = 'https://upload.wikimedia.org/wikipedia/commons/';
    const md5Path = assembleMD5Part(img.name);
    const pictureWidth = 640;

    if(!compressed)
      return baseUrl + md5Path + encodedUri;
    else
      return  baseUrl + 'thumb/' + md5Path + encodedUri + '/' + pictureWidth + 'px-' + encodedUri;
  } catch (e) {
    return "";
  }
 }

import React from 'react';
import Chip  from '@material-ui/core/Chip';
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { action_submit_search_term } from '../../../Modules/redux/actions'

import MyTooltip from "../../Styled/MyTooltip";
const Tooltip = MyTooltip;

class SuggestedTags extends React.Component {

  click(text) {
    const searchTerm = (this.props.searchTerm) ? this.props.searchTerm + " " : "";
    this.props.dispatch(action_submit_search_term(searchTerm + text));
  }

  keywordNotIncluded(searchTerm, keyword){
    return searchTerm === null || !(searchTerm.toLowerCase().includes(keyword.toLowerCase().trim()))
  }

  render(){
    const tags = [];
    const SuggestedTags = this.props.suggestedTags;

    for(let i = 0; i < SuggestedTags.length; i++){
      if(this.keywordNotIncluded(this.props.searchTerm, SuggestedTags[i]))
        tags.push(<Tooltip title="Add search term" enterDelay={1000} key={'tag_'+SuggestedTags[i]}>
                    <Chip className={this.props.classes.chip} label={SuggestedTags[i]} onClick={() => this.click(SuggestedTags[i])} />
                  </Tooltip>);
      if(tags.length >= 10) break;
    }

    return (
        <div style={{margin: '10px 3px 3px 3px', 'paddingBottom': "12px"}}>
          {tags}
        </div>
    );
  }
}

function mapStateToProps(state){
    return {
        searchTerm: state.search.searchTerm,
        suggestedTags: state.search.suggestedTags
    }
}

const styles = {
  chip: {
    margin: '1px',
    height: '26px',
    backgroundColor: '#4e91ca',
    color: 'white',
    opacity: 0.6,
    '&:hover': {
      backgroundColor: '#4E91CA',
      opacity: 1.0,
    }
  }
};

export default connect(mapStateToProps)(withStyles(styles)(SuggestedTags));

import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class HelpDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    window.HelpDialog = this;
    this.handleClose = this.handleClose.bind(this);
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose() {
    this.setState({
      open: false
    });
  }

  render() {
    const help =
      <DialogContent style={styles.dialogContent}>
          <iframe title="tutorial" style={{width:"60vw", height:"40vw", maxWidth: "950px", maxHeight: "750px"}} src="https://www.youtube.com/embed/0B52aW1KFkk?rel=0&amp;vq=hd720&autoplay=1" frameBorder="0" allowFullScreen/>
      </DialogContent>;

    return (
      <Dialog
        open={!!this.state.open}
        onClose={this.handleClose}
        style={styles.dialog}
        maxWidth="md"
        fullWidth={false}
        TransitionComponent={Transition}>
        {help}
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = ({
  dialog: {
    zIndex: 2500,
    fontSize: 14
  },
  dialogContent: {
    paddingBottom: 22,
    display: 'flex',
    maxWidth: '950px',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

export default withStyles(styles)(HelpDialog);

import React, {Component} from 'react';

import Header from './Header/Header';
import Footer from './Footer/Footer';

import ImageDialog from './ImageDialog/ImageDialog';
import SearchInput from "./SearchInput/SearchInput";
import './SideBarContent.css';

import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => ({
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: 0
    },
    sideBarContent:{
        flexGrow: 1,
        overflowY: "auto",
        overflowX: "hidden",
        padding: '0px 5px 0px 5px',
        "& > webkitScrollbarThumb": {
            background: "#888",
            borderRadius: "10px",
            width: "5px",
          },
        "& > scrollbar-track":
        {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
            backgroundColor: "#F5F5F5"
        },
        "& > scrollbar":
        {
            width: "6px",
            "backgroundColor": "#F5F5F5"
        }
    }
});



class SideBarContent extends Component {

    render() {
        return (
            <div className={this.props.classes.mainContainer}>
                <Header theme={this.props.theme}/>

                <div id='sbc' className={this.props.classes.sideBarContent}>
                    <SearchInput theme={this.props.theme}/>
                    <ImageDialog/>
                </div>

                <Footer theme={this.props.theme}/>
            </div>

        );
    }
}

export default withStyles(styles)(SideBarContent);

import React from 'react';
import { getUrlByName } from '../../MainViewContent/ImageSphere/WikiMediaInformationExtractor';
import Button from '@material-ui/core/Button';
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/Grid";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Search from '@material-ui/icons/Search';
import GetApp from '@material-ui/icons/GetApp';
import Link from '@material-ui/icons/Link';
import getEventBus from "../../../lib/ImageSphereEventBus";
import ImageDetails from './ImageDetails';
import {connect} from "react-redux";
import MyPaper from "../../Styled/MyPaper";
const Paper = MyPaper;


class ImageDialog extends React.Component {

  constructor(props){
    super(props);
    
    this.state = {
      isOpen: false,
      image: {},
    };
    this.handleClose = this.handleClose.bind(this);
    this.showResults = this.showResults.bind(this);
    this.save = this.save.bind(this);
    this.eventBus = getEventBus()
  }

  /**
   * @override
   */
  componentDidMount = () => {

    const self = this;
    this.imageClickedId = this.eventBus.subscribe('ImageClicked', function(image){
        if (self.state.image !== image){
          self.setState({
            image: image,
            isOpen: true
          });
        }
    });
  };

  /**
   * @override
   */
  componentWillUnmount = () =>{
    if(this.eventBus)
      this.eventBus.unsubscribe('ImageClicked', this.imageClickedId);
  };

  /**
   * override
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.searchTerm !== prevProps.searchTerm)
      this.setState({isOpen: false});

    if(this.props.showSimilarImages !== prevProps.showSimilarImages)
      this.setState({isOpen: false});
  }

  findSimilar = async () => {
    if(!window.Animator.animationRunning){
      this.handleClose();
      const image = document.getElementById("ImageDialogImage").cloneNode(true);
      const rect = document.getElementById("ImageDialogImage").getBoundingClientRect();
      window.canvas.hideCenterImageButton();
      window.Animator.findSimilar(image, {
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height
      }, {});
      window.CanvasContainer.searchByImage(this.state.image.id, false, true);
      window.CanvasContainer.updateCentralImage(this.state.image.name, 0, this.state.image.id);
    }
  };

  handleClose() {
    this.setState({
      open: false,
    });
  }

  save() {
    const src = document.getElementById("ImageDialogImage").getAttribute('src-uncompressed');
    const name = document.getElementById("ImageDialogImage").getAttribute('name');
    this.forceDownload(src, name);
  }

  showResults() {
    this.setState({isOpen: false});
    this.eventBus.publish('BackToResults');
  }

  forceDownload(url, fileName) {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function(){
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(this.response);
      const tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }

  render() {

    let compressedUrl = "";
    let uncompressedUrl = "";
    if(this.state.image){
      compressedUrl = getUrlByName(this.state.image, true);
      uncompressedUrl = getUrlByName(this.state.image);
    }

    return (
           
        <div style={styles.dialogContent}>
        {this.state.isOpen &&
        <Paper>
          {this.props.showSimilarImages.length > 0 &&
           <Button onClick={this.showResults} >
              <span style={{marginBottom: "5px", color: "#006499"}}>Back to results</span>
            </Button>
          }
          <Grid container spacing={0}>
            <Grid item xs={12} className={this.props.classes.imgContainer}>
              <span className={this.props.classes.helper}/>
                <img style={styles.image} id="ImageDialogImage" alt={compressedUrl} src={compressedUrl} src-uncompressed={uncompressedUrl} name={this.state.image.name}/>
            </Grid>
            <Grid item xs={12} className={this.props.classes.details}>
                
                <ButtonGroup variant="contained" className={this.props.classes.buttonGroup}>
                  <Button onClick={this.findSimilar} className={this.props.classes.button}>
                    <Search style={{marginRight: "4px"}}/>
                    Search Similar
                  </Button>
                  <Button style={{ color: '#FFF' }} href={"https://commons.wikimedia.org/wiki/File:" + this.state.image.name} target="_blank" className={this.props.classes.button}>
                    <Link style={{marginRight: "4px"}}/>
                    Wikimedia
                  </Button>
                  <Button onClick={this.save} className={this.props.classes.button}>
                    <GetApp/>
                  </Button>

                </ButtonGroup>
                <ImageDetails image={this.state.image}/>
                </Grid>
          </Grid>
        </Paper>}
      </div>

               
    );
  }
}

const styles = ({
    details: {
        textAlign: "center",
        position: 'relative',
        userSelect: "text"
    },
    imgContainer: {
        width: "400px",
        height: "400px",
        textAlign: 'center',
        background: 'white',
        marginBottom: 10
    },
    buttonGroup: {
      textAlign: 'center',
      
    },
    button:{
      backgroundColor: "#4e91ca",
      color: "white",

      "&:hover":{
        backgroundColor: "#006499"
      }
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    dialog: {
      height: 640,
      zIndex: 2500
    },
    dialogContent: {
      paddingBottom: 10,
      paddingTop: 10,
      
    },
    helper: {
      display: 'inline-block',
      height: '100%',
      verticalAlign: 'middle',
  }
});

function mapStateToProps(state){
  return {
    showSimilarImages: state.search.similarImages,
    searchTerm: state.search.searchTerm
  }
}


export default connect(mapStateToProps)(withStyles(styles)(ImageDialog));



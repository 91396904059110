import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { feedback } from '../../../API/jsonAPI';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class FeedbackDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      reason: "General"
    };

    window.FeedbackDialog = this;
    this.reasonRef = React.createRef();
    this.emailRef = React.createRef();
    this.feedbackRef = React.createRef();
  }

  handleClickOpen() {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleSend = () => {
    feedback(window.sessionID, this.reasonRef.current.value, this.emailRef.current.value, this.feedbackRef.current.value);
    this.handleClose();
  };

  // outlined selects in dialogs with working label updates are a problem
  // https://github.com/mui-org/material-ui/issues/14203
  // https://codesandbox.io/s/material-demo-03c1m
  // https://codesandbox.io/s/material-demo-ib2dt
  handleChange = (event) => {
    this.setState({
      reason: event.target.value
    });
  };

  render() {

    const feedback =
      <DialogContent className={this.props.classes.dialogContent}>

        <h1>Feedback</h1>
        Share your ideas for improvements. In case of problems please
        describe your issue and leave your email address if you wish
        to be contacted. If you like wikiview please let other people know.

          <FormControl className={this.props.classes.formControl}>

            <Select
              value={this.state.reason}
              onChange={this.handleChange}
              input={<OutlinedInput />}
              inputRef={this.reasonRef}
              className={this.props.classes.dialogInput}
            >
              <MenuItem value={"General"}>General</MenuItem>
              <MenuItem value={"Improvement"}>Improvement</MenuItem>
              <MenuItem value={"Issue"}>Issue</MenuItem>
            </Select>

            <TextField
              label="E-Mail Address (optional)"
              defaultValue=""
              margin="normal"
              variant="outlined"
              inputRef={this.emailRef}
              className={this.props.classes.dialogInput}
            />

            <TextField
              label="Please let us know how you like wikiview."
              multiline
              rows="4"
              required={true}
              defaultValue=""
              margin="normal"
              variant="outlined"
              inputRef={this.feedbackRef}
              className={this.props.classes.dialogInput}
            />
          </FormControl>
      </DialogContent>;

    return (
      <Dialog
        open={!!this.state.open}
        onClose={this.handleClose}
        className={this.props.classes.dialog}
        maxWidth="md"
        fullWidth={false}
        TransitionComponent={Transition}>
        {feedback}
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            close
          </Button>
          <Button onClick={this.handleSend} color="primary">
            send
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialog: {
    zIndex: 2500,
    fontSize: 14,
    userSelect: "text",
  },
  dialogContent: {
    paddingBottom: 22,
    maxWidth: '600px',
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'justify'
  },
  formControl: {
    width: "100%",
    margin: theme.spacing(1),
    minWidth: 120,
  },
  dialogInput:{
    minHeight: "45px"
  }

});

export default withStyles(styles)(FeedbackDialog);

import React from 'react';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faTh, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { MAX_LOCAL_MAP_SIZE, MIN_LOCAL_MAP_SIZE } from '../../../config'
import { grid2x2, grid3x3 } from "../../../lib/SettingIcons"

import {
  action_shape_value,
  action_background_color,
  action_gridside_length
} from '../../../Modules/redux/actions'

class SettingsSliderContainer extends React.Component {

  constructor(props) {
    super(props);
    window.Slider = this;
  }

  componentDidMount(){
    const backgroundColor = window.localStorage.getItem("wikimediaImageSphereBackgroundColor");
    const shapeValue = window.localStorage.getItem("wikimediaImageSphereShapeValue");
    const gridSideLength = window.localStorage.getItem("wikimediaImageSphereGridSideLength");

    if (backgroundColor) this.props.dispatch(action_background_color(backgroundColor));
    if (shapeValue) this.props.dispatch(action_shape_value(shapeValue));
    if (gridSideLength) this.props.dispatch(action_shape_value(shapeValue));
  }

  handleShapeValueChange(event, value){
    window.localStorage.setItem("wikimediaImageSphereShapeValue", value);
    this.props.dispatch(action_shape_value(value));
  };

  handleBackgroundColorChange( event, value){
    window.localStorage.setItem("wikimediaImageSphereBackgroundColor", value);
    this.props.dispatch(action_background_color(value));
  };

  handlegridSideLengthChange(event, value){
    window.localStorage.setItem("wikimediaImageSphereGridSideLength", value +1);
    this.props.dispatch(action_gridside_length(value + 1));
  }

  // https://github.com/mui-org/material-ui/issues/10845#issuecomment-377367600
  render() {
    return (
      <div >
        <div className={this.props.classes.wrapper}>
          <Typography id="Shape Value" className={this.props.classes.typography} > <FontAwesomeIcon icon={faTh} />  </Typography>
          <PrettoSlider
            value={parseInt(this.props.shapeValue)}
            aria-labelledby="Shape Value"
            onChange={this.handleShapeValueChange.bind(this)}
            min={0} max={100} step={5}
            height='15px'
          />
          <Typography id="Shape Value" className={this.props.classes.typography} > <FontAwesomeIcon icon={faGlobe} /> </Typography>
        </div>
        <div className={this.props.classes.wrapper}>
          <Typography id="Grid Size" className={this.props.classes.typography} > <FontAwesomeIcon icon={grid2x2}/>  </Typography>
          <PrettoSlider
            value={parseInt(this.props.gridSideLength)}
            aria-labelledby="Grid Size"
            onChange={this.handlegridSideLengthChange.bind(this)}
            min={MIN_LOCAL_MAP_SIZE-1} max={MAX_LOCAL_MAP_SIZE-1} step={2}
          />
          <Typography id="Grid Size" className={this.props.classes.typography} >  <FontAwesomeIcon icon={grid3x3} /> </Typography>
        </div>
        <div className={this.props.classes.wrapper}>
          <Typography id="Background Color" className={this.props.classes.typography} > <FontAwesomeIcon icon={faMoon}/>  </Typography>
          <PrettoSlider
            value={parseInt(this.props.backgroundColor)}
            aria-labelledby="Background Color"
            onChange={this.handleBackgroundColorChange.bind(this)}
            min={0} max={240} step={5}
          />
          <Typography id="Background Color" className={this.props.classes.typography} >  <FontAwesomeIcon icon={faSun} /> </Typography>

        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    shapeValue: state.settings.shapeValue,
    backgroundColor: state.settings.backgroundColor,
    gridSideLength: state.settings.gridSideLength
  }
}

// https://material-ui.com/styles/basics/
const PrettoSlider = withStyles({
  root: {
    color: '#006499',
    height: 8,
    marginRight: '5px',
    marginLeft: '10px'
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -5,
    marginLeft: -9,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 6,
    borderRadius: 3,
  },
  rail: {
    height: 6,
    borderRadius: 3,
  },
})(Slider);

const styles = () => ({
  wrapper: {
    width: '95%',
    display: 'flex'
  },
  typography: {
    width: '10%',
    marginLeft: 10,
    marginRight: 3,
    lineHeight: 2,
    display: "flex",
    alignItems: "center",
    fontSize: "17px"
  }
});

export default connect(mapStateToProps)(withStyles(styles)(SettingsSliderContainer));


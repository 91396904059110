import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class AboutDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    window.AboutDialog = this;
    this.handleClose = this.handleClose.bind(this);
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose() {
    this.setState({
      open: false
    });
  }

  render() {

    const about =
      <DialogContent style={styles.dialogContent} ref={ref => {
        if(ref)
          ref.innerHTML = document.getElementById("info").innerHTML;
        return ref;
      }}>
      </DialogContent>;


    return (
      <Dialog
        open={!!this.state.open}
        onClose={this.handleClose}
        style={styles.dialog}
        maxWidth="md"
        fullWidth={false}
        TransitionComponent={Transition}>
        {about}
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = ({
  dialog: {
    zIndex: 2500,
    fontSize: 14,
    userSelect: "text"
  },
  dialogContent: {
    paddingBottom: 22,
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'justify'
  },
  imprint: {
    width: 500,
  }
});

export default withStyles(styles)(AboutDialog);

import React from 'react';
import Button from '@material-ui/core/Button';
import withStyles from "@material-ui/core/styles/withStyles";

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    
    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }
    
    render() {
      if (this.state.errorInfo) {
        // Error path
        return (
          <div className={this.props.classes.panel}>
            <h2>Something went wrong. Please refresh this application.</h2>
            <Button onClick={()=>window.location.reload()} color="primary">
                Refresh
            </Button>
          </div>
        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }

const styles= {
    panel:{
        alignItems: "center",
        background: "#fff",
        bottom: 0,
        flexDirection: "column",
        justifyContent: "center",
        left: 0,
        padding: "30px",
        position: "fixed",
        right: 0,
        textAlign: "center",
        top: 0,
        zIndex: 9999,
        "& > h2":{
            fontFamily: "Oswald, sans-serif"
        },
        "& > button":{
            backgroundColor: "#006499",
            color: "white",
            "&:hover": {
                backgroundColor: "#003a6a"
            }
            
        }
    }
};

export default withStyles(styles)(ErrorBoundary);
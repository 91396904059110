import React from 'react';
import { extractWikimediaInformation } from '../../MainViewContent/ImageSphere/WikiMediaInformationExtractor';
import withStyles from "@material-ui/core/styles/withStyles";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';


class ImageDetails extends React.Component {

  constructor(props){
    super(props);

    this.state = {};
    if(this.props.image && this.props.image.name){
      this.imageInfoToState()
    }
  }

  extractArtist(artist) {
    let regex = /<\s*a[^>]*>(.*?)<\s*\/\s*a>/;
    if (artist != null) {
       const author = artist.value;
       const authorPossibleMatches = author.match(regex);
       if (authorPossibleMatches != null) {
         return authorPossibleMatches[1]
       } else {
        return author.replace(/(<([^>]+)>)/ig, " ")
       }
    }
    return ""
  }

  formatTitle(name){
    let title = name.replace(/_/g, ' ');
    return title.substring(0, title.lastIndexOf('.'))
  }

  formatDate(dateObj){
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    if(dateObj){
      const date = dateObj.value;
      const monthId = parseInt(date.substring(date.indexOf('-') +1, date.lastIndexOf('-')), 10);
      const monthName = monthNames[monthId-1];
      const dayNr = date.substring(date.lastIndexOf('-') +1, date.indexOf(' '));
      const yearNr = date.substring(0, date.indexOf('-'));

      return monthName + ' ' + dayNr + ', ' + yearNr;
    }
    return '';
  }

  formatDescription(descriptionObj){
    if(descriptionObj){
      let description = descriptionObj.value;
      description = description.replace(/<[^>]*>?/gm, ' '); //No html tags
      description = this.reduceSpaces(description);
      if(description.length > 100){
        this.setState({
          descriptionBlock: true,
        });
      }
      const maxChars = 300;
      if(description.length >= maxChars){
        let idToCut = description.slice(0, maxChars).lastIndexOf(',');
        if(idToCut === -1) idToCut = description.slice(0, maxChars).lastIndexOf(' ');
        description = (description.slice(0, idToCut) + ' <a href="https://commons.wikimedia.org/wiki/File:' + this.props.image.name + '"  target="_blank"> more on Wikimedia </a>');
      }
      return {__html: description};
    }
    return  {__html: ""};
  }

  reduceSpaces(text){
    //Remove double spaces
    text = text.replace(/\s{2,}/g, ' ');
    //No space before :
    text = text.replace(/\s*:/g, ':');
    text = text.replace(/\s*,/g, ',');
    text = text.replace(/\s*;/g, ';');
    text = text.replace(/\(\s*/g, '(');
    text = text.replace(/\s*\)/g, ')');
    return text;
  }

  async imageInfoToState(){
    if (this.state.imageInfo) this.setState({imageInfo: null});
    const imageInfo = await extractWikimediaInformation(this.props.image.name);

    if(imageInfo){
      this.setState({
        title: this.formatTitle(this.props.image.name),
        artist: this.extractArtist(imageInfo.Artist),
        date: this.formatDate(imageInfo.DateTime),
        description: this.formatDescription(imageInfo.ImageDescription),
        license: imageInfo.LicenseShortName ? imageInfo.LicenseShortName.value : "",
        pageUrl: "https://commons.wikimedia.org/wiki/File:" + this.props.image.name,
        imageInfo: imageInfo
      });
    }
  }

  /**
   * @override
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.image !== prevProps.image) this.imageInfoToState();
  }

  render() {

    let description;
    if(this.state.descriptionBlock)
      description = this.props.classes.description;

    if(this.state.imageInfo){
      return (
          <div className={this.props.classes.detailsWrapper}>
            <div className={this.props.classes.details}>
              <Box m={3}>
                <h4>{this.state.title}</h4>
                <div className = {description} dangerouslySetInnerHTML={this.state.description} />
              </Box>

            <Table size="small">
              <TableBody>
                <TableRow>
                <TableCell component="th" scope="row" colSpan={2}> Author </TableCell>
                <TableCell>{this.state.artist}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={2}>Date</TableCell>
                <TableCell>{this.state.date}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={2}>License</TableCell>
                <TableCell colSpan={4}>{this.state.license}</TableCell>
              </TableRow>
            </TableBody>
            </Table>
            </div>
          </div>
      );
    } else {
      return (
        <div className={this.props.classes.detailsWrapper}>
          <div style={{ height: "50%", position: "absolute", top: "50%", left: "50%"}}>
              <CircularProgress/>
          </div>
        </div>
      )
    }


  }
}

const styles = ({
    detailsWrapper: {
        minHeight: 200,
        marginBottom: 8,
        display : 'flex',
        alignItems : 'center',
      },
      details: {
        width: '100%'
      },
      description: {
        textAlign: 'justify',
      }
  });

export default withStyles(styles)(ImageDetails);

import React from 'react'
import $ from 'jquery';

export default class Animator extends React.Component {

  constructor(props){
    super(props);
    window.Animator = this;
    this.fadeOutDuration = 500;
    this.fadeInDuration = 250;
    this.imageCount = 0;
    this.animationRunning = false;
  }

  findSimilar(image, start, options, callback, callbackArgs){
    this.startBusyAnimation();
    this.fadeOutStartTime = + new Date();
    $("#mainCanvas").fadeOut(this.fadeOutDuration);
    this.prepareImage(image, start, options.prepareWithOffset);

    window.canvas.xm = 0;   // Canvas wieder in Ausgangsposition setzen 
    window.canvas.ym = 0;
    window.canvas.updateCanvas(0, 0, 0, 0); 

    //let centralImage = window.canvas.getClosestImageToCenter();
    let centralImage = window.canvas.getCentralCell(); 

    let centralImageStyleTop;
    let centralImageStyleLeft;

    
    
    if(options.reduceTargetTopByHalfHeight) 
      centralImageStyleTop = parseInt(centralImage.coordy) + centralImage.ySize / 2 + "px";
    else 
      centralImageStyleTop = parseInt(centralImage.coordy) + "px";

    centralImageStyleLeft = this.computeSidBarOffset(parseInt(centralImage.coordx) + "px");
    $(image).animate({
      width: centralImage.xSize + "px",
      height: centralImage.ySize + "px",
      left: centralImageStyleLeft,
      top: centralImageStyleTop
    }, this.fadeOutDuration, () => {
      //Animation finished .. 
      if(callback && callbackArgs) {
        setTimeout(() => {
          callback(...callbackArgs);
        },this.fadeOutDuration);
      }
    });
  }

  zoom(image, start, zoomIn=true){
    this.startBusyAnimation();
    this.fadeOutStartTime = + new Date();
    $("#mainCanvas").fadeOut(this.fadeOutDuration);
    this.prepareImage(image, start, true);
    $(image).stop();
    $(image).css({opacity: 1});
    $(image).show();

    let widthChangeStart = "+=10px";
    let heightChangeStart = "+=10px";
    let leftChangeStart = "-=5px";
    let topChangeStart = "-=5px";

    let widthChangeEnd = "-=10px";
    let heightChangeEnd = "-=10px";
    let leftChangeEnd = "+=5px";
    let topChangeEnd = "+=5px";

    if(!zoomIn){
      widthChangeStart = "-=20px";
      heightChangeStart = "-=20px";
      leftChangeStart = "+=10px";
      topChangeStart = "+=10px";

      widthChangeEnd = "+=20px";
      heightChangeEnd = "+=20px";
      leftChangeEnd = "-=10px";
      topChangeEnd = "-=10px";
    }
    $(image).animate({
      width: widthChangeStart,
      height: heightChangeStart,
      left: leftChangeStart,
      top: topChangeStart
    }, 250);
    $(image).animate({
      width: widthChangeEnd,
      height: heightChangeEnd,
      left: leftChangeEnd,
      top: topChangeEnd,
    }, 250, () => {
      //Animation finished ..

    });
  }

  prepareImage(image, start, withOffset){
    image.classList.add("animatedImage");
    image.style.position = 'absolute';

    image.style.zIndex = 2000 + this.imageCount;
    this.imageCount ++;

    //image.style.filter = "blur(1px)";

    image.style.top = start.top + "px";
    image.style.left = start.left + "px";
    image.style.width = start.width + "px";
    image.style.height = start.height + "px";
    if(withOffset) {
      image.style.left = this.computeSidBarOffset(image.style.left);
    }
    document.body.append(image);
    $(image).show();
  }

  computeSidBarOffset(leftPosition){
    
    let sidebarWidth = 0;
    if(window.app.state.open){
      sidebarWidth = document.getElementById("drawer").offsetWidth;
    }
    const leftOffset = sidebarWidth;
    return parseInt(leftPosition.replace(/px/,"")) + leftOffset + "px";
  }

  startBusyAnimation(){
    $('html,body').css('cursor','wait');
    this.animationRunning = true;
  }

  endBusyAnimation(){
    $('html,body').css('cursor','auto');
    this.animationRunning = false;
  }

  canvasHasBeenUpdated(){
    this.animationHasFinished();
  }

  animationHasFinished(){
    $("#mainCanvas").fadeIn(this.fadeInDuration, () => {
      //$(".animatedImage").fadeOut(350);
      $(".animatedImage").remove();
      this.endBusyAnimation();
      window.canvas.isBusy = false;
    });
    $("#overlayCanvas").fadeOut(this.fadeOutDuration, () => {
      $("#overlayCanvas").remove();
    });
  }

  render(){
    return null;
  }

  /**
   * @override
   */
  componentDidMount(){
    $('#animationInProgress').fadeOut(0);
  }

}

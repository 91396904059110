import { GRAFFLE_DOMAIN } from '../config'
import {NotificationManager} from 'react-notifications';
import JSONGenerator from './jsonGenerator';
import store from '../Modules/redux/store';
import {action_session_expired} from "../Modules/redux/actions";
import axios from 'axios';
const jsonGen = new JSONGenerator();

export function testHelloWorld(sessionID){
  const url = `${GRAFFLE_DOMAIN}/${sessionID}/hello/`;
  return axios.get(url)
    .then(response => {
      return response.data
    }).catch(function (error) {
      handleError(error);
    });
}

export function debug(sessionID){
  const url = `${GRAFFLE_DOMAIN}/${sessionID}/snapshot/`;
  return axios.get(url)
    .then(response => {
      return response.data;
    }).catch(function (error) {
      handleError(error);
    });
}

/**
* Creates a new world map given a sessionId which is ideally 10 times as large as the viewport
*
* @param {string} sessionID A unique ID that identifies the current user
* @param {number} rows The number of image rows
* @param {number} columns The number of image columns
*/
export function initWorldMap(sessionID, rows, columns){
  const url = `${GRAFFLE_DOMAIN}/${sessionID}/init/${rows}/${columns}/`;
  return axios.get(url)
    .then(response => {
      return response.data;
    }).catch(function (error) {
      handleError(error);
    });
}

/**
* Initializes a viewport of the given size. On the first call it is set on a random graph position.
* This viewport is saved into the world map.
*
* @param {string} sessionID A unique ID that identifies the current user
* @param {number} rows The number of image rows
* @param {number} columns The number of image columns
* @returns {Promise} Promise represents an array of panels
*/
export function initViewPort(sessionID, rows, columns){
  const url = `${GRAFFLE_DOMAIN}/${sessionID}/viewport/${rows}/${columns}/`;
  return axios.get(url)
    .then(response => {
      return response.data;
    }).catch(function (error) {
      handleError(error);
    });
}

/**
* Clears the world map.
* Searches on the given level for a similar image based on the given imageID.
* Jumps to the resulting image in the graph and creates a new viewport.
* The result image is placed on the provided position in the viewport and
* the rest of the viewport is filled with according images.
* The viewport content is saved into the world map.
*
* @param {string} sessionID A unique ID that identifies the current user
* @param {number} imageId A unique ID that identifies the given image
* @param {number} posX The x position of the viewport where the similar is placed
* @param {number} posY The y position of the viewport where the similar is placed
* @param {number} level The level for searching for images
* @returns {Promise} Promise represents an array of panels
*
 * https://github.com/axios/axios/issues/1143
*/
export function jumpToId(sessionID, imageId, posX, posY, level){
  const url = `${GRAFFLE_DOMAIN}/${sessionID}/jump/${imageId}/${posX}/${posY}/${level}`;
  return axios.get(url)
    .then(response => {
      return response.data;
    }).catch(function (error) {
      handleError(error);
      return error;
    });
}

/**
 * Clears the world map.
 * Searches on the given level for a similar image based on the given filename.
 * Jumps to the resulting image in the graph and creates a new viewport.
 * The result image is placed on the provided position in the viewport and
 * the rest of the viewport is filled with according images.
 * The viewport content is saved into the world map.
 *
 * @param {string} sessionID A unique ID that identifies the current user
 * @param {string} filename unique name that identifies the given image
 * @param {number} posX The x position of the viewport where the similar is placed
 * @param {number} posY The y position of the viewport where the similar is placed
 * @param {number} level The level for searching for images
 * @returns {Promise} Promise represents an array of panels
 */
export function jumpToFile(sessionID, filename, posX, posY, level){
  const url = `${GRAFFLE_DOMAIN}/${sessionID}/jump/file/`;
  const bodyFormData = new FormData();
  bodyFormData.append('filename', filename);
  bodyFormData.append('posX', String(posX));
  bodyFormData.append('posY', String(posY));
  bodyFormData.append('level', String(level));

  return axios.post(url, bodyFormData, { headers: {'Content-Type': 'x-www-form-urlencoded' }})
    .then(response => {
      return response.data;
    }).catch(function (error) {
      handleError(error);
      return error;
    });
}

/**
* Moves the viewport given some coordinates and a direction vector
*
* @param {string} sessionID A unique ID that identifies the current user
* @param {number} x Number of cells to translate in x direction,
* @param {number} y Number of cells to translate in y direction
* @param {number} directionX Direction vector x coordinate
* @param {number} directionY Direction vector y coordinate
* @returns {Promise} Promise represents an array of panels
*/
export function move(sessionID, x, y, directionX, directionY){
  const url = `${GRAFFLE_DOMAIN}/${sessionID}/move/${x}/${y}/${directionX}/${directionY}`;
  return axios.get(url)
    .then(response => {
      return response.data;
    }).catch(function (error) {
      handleError(error);
    });
}

/**
 * Random image at given level
 *
 * @param sessionID
 * @param {number} level
 * @return {Promise<AxiosResponse<T>>}
 */
export function random(sessionID, level){
  const url = `${GRAFFLE_DOMAIN}/${sessionID}/image/random/${level}/`;
  return axios.get(url)
    .then(response => {
      return response.data;
    }).catch(function (error) {
      handleError(error);
    });
}

/**
 * Find the images best described by the uploaded example image.
 * Data is transfered via a POST request and a "Form Data" parameter called "file".
 * The amount of result images should be specified in the URL.
 *
 * @param sessionID
 * @param imgFile
 * @param imgCount
 * @return
 */

export async function similarImageUpload(sessionID, imgFile, imgCount){
  const url = `${GRAFFLE_DOMAIN}/${sessionID}/search/image/${imgCount}`;
  return resizeImage(imgFile, 640).then(resizedImg => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', resizedImg);
    return axios.post(url, bodyFormData, { headers: {'Content-Type': 'multipart/form-data' }})
      .then(function (response) {
        return response.data;
      }).catch(function (error) {
        handleError(error);
      });
  })
}


// https://zocada.com/compress-resize-images-javascript-browser/
export async function resizeImage(imgFile, imgSize, asURL = false){
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(imgFile);
    let file;
    reader.onload = event => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const elem = document.createElement('canvas');
        const width = imgSize;
        const scaleFactor = width / img.width;
        elem.width = width;
        elem.height = img.height * scaleFactor;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
        if (asURL) resolve(elem.toDataURL());
        ctx.canvas.toBlob((blob) => {
          file = new File([blob], imgFile.name, {
            type: 'image/jpeg',
            lastModified: Date.now()
          });
          resolve(file);
        }, 'image/jpeg', 1);
      };
    };
    //reader.error =
  })
}

/**
 * Search for images by keyword
 *
 * @param {string} sessionID Session ID of the current user
 * @param {string} keyword The keyword to search for
 * @param {number} count Amout of result images
 */
export function searchKeyword(sessionID, keyword, count) {
  const url = `${GRAFFLE_DOMAIN}/${sessionID}/search/query/`;
  return axios.post(url, jsonGen.getSearchObject(count))
    .then(response => {
      return response.data
    }).catch(function (error) {
      handleError(error);
    });
}

/**
 * Search for images by a image id
 *
 * @param {string} sessionID Session ID of the current user
 * @param {number} imageId id of the image
 * @param {number} count Amout of result images
 * @return {Promise<AxiosResponse<T>>}
 */
export function searchImageId(sessionID, imageId, count){
  const url = `${GRAFFLE_DOMAIN}/${sessionID}/search/id/${imageId}/${count}/`;
  return axios.get(url)
    .then(response => {
      return response.data
    }).catch(function (error) {
      handleError(error);
    });
}

/**
 * Apply filter to all routes with given SQL string
 *
 * @param {string} sessionID Session ID of the current user
 * @param {boolean} resetFilters sql string used for filtering
 */
export function filter(sessionID, resetFilters) {
  let filterObj = jsonGen.getLicenseFilterObject();
  
  if(resetFilters) filterObj = {"cc":1, "by":1, "sa":1, "pd":1, "other":1};
  const url = `${GRAFFLE_DOMAIN}/${sessionID}/filter/`;
  return axios.post(url, filterObj)
    .then(response => {
      if(response.data.reduce((pv, cv) => pv + cv, 0) > 0){
        return response.data
      } else {
        NotificationManager.warning('no results for provided filter!', '', 5000, ()=>{}, true);
        return response.data
      }
    }).catch(function (error) {
      handleError(error);
    });
}

/**
 * Send a feedback
 *
 * @param {string} sessionID Session ID of the current user
 * @param {string} reason
 * @param {string} email
 * @param {string} feedback
 * @return {Promise<AxiosResponse<T>>}
 */
export function feedback(sessionID, reason, email, feedback){
  const url = `${GRAFFLE_DOMAIN}/${sessionID}/feedback/`;
  const bodyFormData = new FormData();
  bodyFormData.append('reason', reason);
  bodyFormData.append('email', email);
  bodyFormData.append('feedback', feedback);

  return axios.post(url, bodyFormData, { headers: {'Content-Type': 'x-www-form-urlencoded' }})
    .then(response => {
      return response.data
    }).catch(function (error) {
      handleError(error);
    });
}

/**
 * Normal error handling
 *
 * @param error
 */
function handleError(error) {
  console.log(error);
  if (error.response && error.response.status === 511)
    store.dispatch(action_session_expired(true));
}

export function fetchSuggestedKeywords(sessionID, value){
  const url = `${GRAFFLE_DOMAIN}/${sessionID}/suggest/${value}/10/`;
  return axios.get(url)
    .then(response => {
      return response.data
    }).catch(function (error) {
      handleError(error);
    });
}
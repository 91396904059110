import { IMAGE_BASE_URL } from '../../../config'
import md5 from 'md5';

export default class DataPreprocessor {

   startProcessingPipeline(data, gridSideLength) {
    const sortedTiles = this.sortTilesFromCenter(data.tiles, gridSideLength);
    return this.generateImageArray2D(sortedTiles, gridSideLength);
  }

  sortTilesFromCenter(tiles, gridSideLength){
    const center = gridSideLength / 2;
    return tiles.sort((a, b) => {
      if (a === null || b === null) {
        return 0
      }
      const distA = Math.sqrt(Math.pow(a.x - center, 2) + Math.pow(a.y - center, 2));
      const distB = Math.sqrt(Math.pow(b.x - center, 2) + Math.pow(b.y - center, 2));
      return distA - distB
    });
  }

  generateImageArray2D(tiles, gridSideLength){
    let imageArray2D = [];

    tiles.forEach(async (tile, tileIndex) => {
      if(!imageArray2D[tile.y]){
        imageArray2D[tile.y] = [];
      }
      let imageData = tile.content ? Object.assign({}, tile.content) : null;
      imageArray2D[tile.y][tile.x] = this.createImageElement(imageData);
    });

    let imageArray2DT = new Array(gridSideLength);
    
    for (let y = 0; y < imageArray2D.length; y++) {
      imageArray2DT[y] = new Array(gridSideLength);
      for (let x = 0; x < imageArray2D[y].length; x++) {
        const tx = x;
        const ty = y;
        
        if (imageArray2D[ty] && imageArray2D[ty][tx]){
          imageArray2DT[y][x] = imageArray2D[ty][tx];
        }else{
          imageArray2DT[y][x] = 'preview';
        }

      }
      
    }
  
    return imageArray2DT
   
  }

  onLoadHandler(event) {
    event.target.loaded = true;
  }

  createImageElement(imageData) {

    if(imageData){
      let image = new Image();
      image.loaded = false;
      let md5_src = md5(imageData.path);
      let first_dir = md5_src.charAt(0);
      let second_dir = first_dir + md5_src.charAt(1);
      let third_dir = second_dir + md5_src.charAt(2);
      let img_path = '/' + first_dir + '/' + second_dir + '/' + third_dir + '/' + md5_src + '.jpg';
      
      image.src = `${IMAGE_BASE_URL}${img_path}`;
      image.id = imageData.id;

      image.onload = this.onLoadHandler;

      image.width = 256;
      image.height = 256;
      image.setAttribute("name", imageData.path);
      return image;
    }
  }
}

import React from 'react'
import {connect} from "react-redux";
import {IMAGE_BASE_URL} from '../../../config'

import GridList from '@material-ui/core/GridList';
import GridListTile from "@material-ui/core/GridListTile/GridListTile";
import withStyles from "@material-ui/core/styles/withStyles";
import getEventBus from "../../../lib/ImageSphereEventBus";
import md5 from 'md5';


class SimilarImagesBox extends React.Component {

  constructor(props){
    super(props);
    window.SimilarImagesBox = this;
    this.eventBus = getEventBus();
    this.state = {
      activeImageId: -1,
      isOpen: true,
      newSearch: false
    }
  }

  /**
   * @override
   */
  componentDidMount = () => {
    const self = this;
    this.imageClickedId = this.eventBus.subscribe('ImageClicked', function(){
      self.setState({isOpen: false});
    });
    this.backToResultsId = this.eventBus.subscribe('BackToResults', function(){
      self.setState({isOpen: true});
    });
  };

  /**
   * @override
   */
  componentWillUnmount = () =>{
    this.eventBus.unsubscribe('ImageClicked', this.imageClickedId);
    this.eventBus.unsubscribe('BackToResults', this.backToResultsId);
  };

  /**
   * @override
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot){

    if((this.props.similarImages === prevProps.similarImages)) return;

    if(this.props.similarImages !== prevProps.similarImages && this.state.activeImageId !== -1){
      this.setState({"activeImageId": -1});
    }

    if(this.props.similarImages[0]){

      this.setState({"isOpen": true});
      this.newSearch = true;
      const imageId = "imageTile-" + this.props.similarImages[0].id;
      if (!this.props.similarImages[0].skipAnimation){
        setTimeout(() => {
          let firstImage = document.getElementById(imageId);
          if(firstImage)
            firstImage.click();
        }, 500);
      }
    }
  }

  getSimilarImagesPaths(similarImages) {

    if (similarImages) {
      return similarImages.map((img) => {
        let md5_src = md5(img.path);
        let first_dir = md5_src.charAt(0);
        let second_dir = first_dir + md5_src.charAt(1);
        let third_dir = second_dir + md5_src.charAt(2);
        let img_path = '/'+ first_dir + '/'+ second_dir + '/' + third_dir + '/' + md5_src + '.jpg';
        return {
          path: `${IMAGE_BASE_URL}${img_path}`,
          id: img.id
        }
      })
    } else return [];
  }

  onImageTileClick(imageID, filename) {
    if (imageID && !window.Animator.animationRunning) {

      const image = document.getElementById("imageTile-" + imageID).cloneNode(true);
      const rect = document.getElementById("imageTile-" + imageID).getBoundingClientRect();
      image.style.border = "None";
      
      this.setState({"activeImageId": parseInt(image.id.split("-")[1])});

      if(this.newSearch)
        this.newSearch = false;

      window.Animator.findSimilar(image, {
        top: rect.top + rect.height/2,
        left: rect.left,
        width: rect.width,
        height: rect.height
      }, {reduceTargetTopByHalfHeight: true}); //,  animatorCallback, cbArgs

      window.CanvasContainer.updateCentralImage(filename, 0, imageID);
    }
  }

  getImageTileClass(id, index){
    // if no tile has been clicked mark the first one as active
    if(this.state.activeImageId === id || (this.state.activeImageId === -1 && index === 0))
      return this.props.classes.activeTile;
    else
      return this.props.classes.imgTile
  }

  render() {
    const similarImagesPaths = this.getSimilarImagesPaths(this.props.similarImages);
    return (
      <div className={this.props.classes.container}>
        {this.state.isOpen &&
        <GridList cellHeight={'auto'} cols={3}>
          {similarImagesPaths.map((tile, index) => (
            <GridListTile key={"key-" + index}>
              <img id={"imageTile-" + tile.id} className={ this.getImageTileClass(tile.id, index) } src={tile.path} alt={tile.path} onClick={() =>
                this.onImageTileClick(tile.id, this.props.similarImages[index].path)} />
            </GridListTile>
          ))}
        </GridList>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    color: state.filter.color,
    similarImages: state.search.similarImages
  }
}

const styles =({
    imgTile:{
      width: "100%",
      cursor: 'pointer'
    },
    activeTile:{
      width: "100%",
      borderStyle: "solid",
      borderWidth: "5px", 
      borderColor: "rgb(78, 145, 202)",
      cursor: 'pointer'
    },
    container: {
      margin: '0px 12px 0px 12px',
      paddingBottom: '12px'
    }
});

export default connect(mapStateToProps)(withStyles(styles)(SimilarImagesBox));

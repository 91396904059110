class EventBus {
    constructor(){
        this.subscribers = {};
        const EventEnum = Object.freeze({"ImageClicked":1, "BackToResults": 2});
        this.EventEnum = EventEnum
    }

    subscribe(eventName, callback){
        if (typeof this.EventEnum[eventName] !== 'undefined'){
            // youre subscribing to a existing event for future reference
            if (typeof this.subscribers[eventName] === 'undefined'){ 
                this.subscribers[eventName] = []
            }
            const subscriptionId = this.makeid();
            this.subscribers[eventName].push(Object.freeze({"id":subscriptionId, 'callback': callback}));
            return subscriptionId
        }
    }

    unsubscribe(eventName, subscriptionId){
        if (typeof this.EventEnum[eventName] !== 'undefined' && typeof this.subscribers[eventName] !== 'undefined'){
            
            let index =  -1;
            for ( let i = 0; i < this.subscribers[eventName].length; i++ ) {
                if(this.subscribers[eventName][i]['id'] === subscriptionId){
                    index = i
                }
             }
             this.subscribers[eventName] = this.subscribers[eventName].splice(index, 1)
        }
    }

    publish(eventName, ...args){
        if (typeof this.subscribers[eventName] === 'undefined'){ 
            return 
        }
        this.subscribers[eventName].forEach(obj => {
            obj['callback'](...args)
        })
    }

    makeid() {
        let result           = '';
        const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for ( let i = 0; i < 16; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

}




const eventBus = new EventBus();

const getEventBus = () => {
    return eventBus
};

export default getEventBus.bind(this);

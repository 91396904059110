import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

class Footer extends React.Component {

  about = (e) => {
    e.preventDefault();
    window.AboutDialog.handleClickOpen();
  };

  render() {
    return (
      <div className={this.props.classes.footer}>
        <a href="https://www.htw-berlin.de/impressum/" target="_blank" rel="noopener noreferrer nofollow">Imprint</a>
        <a href="https://www.htw-berlin.de/datenschutz/" target="_blank" rel="noopener noreferrer nofollow">Privacy Policy</a>
        <a href="#" onClick={this.about} >About</a>
      </div>
    );
  }
}

const styles = ({
  footer: {
    padding: "10px 10px",
    borderTop: "1px solid #dadce0",
    textAlign: "center",
    marginTop: "auto",

    "& a": {
      display: "inline-block",
      padding: "0 8px",
      textDecoration: "none",
      color: "#000",
      fontSize: "18px",
      whiteSpace: "nowrap"
    },
  }
});

export default withStyles(styles)(Footer);

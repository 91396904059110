import {createMuiTheme} from '@material-ui/core/styles';

//const font = 'Gugi';

const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            light: '#ce422b',
            main: '#960000',
            dark: '#1976d2',
        },
        secondary: {
            light: '#4e91ca',
            main: '#006499',
            dark: '#003a6a',
        },
        action:{
            active: '#4e91ca',
        },
        background:{
            paper: '#888',
            main: '#DCDCDC',
        },
        text:{
            primary: '#fff'
        }
        // error: will use the default color
    },
    typography: {
        //fontFamily: font,
        fontSize: 12,
    },
    overrides: {
        MuiFormLabel:{
            root:{
                margin: "0 0 10px 10px",
                fontSize: "18px"
            }
        },
        MuiInputBase:{
            input: {
                padding: "12px",
                width: "220px"
            }
        },
        MuiToggleButton:{
            root:{
                border: "0px",
                '&$selected': { // <-- mixing the two classes
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiFab:{
            root:{
                "&:disabled": {
                    background: "#888"
                },
            },
            secondary:{
                "&:disabled": {
                    "&:hover": {
                        backgroundColor: "#888",
                        cursor: "default"
                    },
                }
            }
        },
        MuiCollapse:{
            entered:{
                height: "500px !important",
            }
        }
    }
});

export default theme;

import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withStyles from '@material-ui/core/styles/withStyles';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';

import {connect} from "react-redux";
import {action_session_expired} from "../../Modules/redux/actions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class SessionExpiredDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleReload = this.handleReload.bind(this);
  }

  handleClose() {
    this.props.dispatch(action_session_expired(false));
  }


  /**
   * Reload the page
   */
  handleReload() {
    window.location.reload();
  }

  /**
   * @override
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.isSessionExpired !== this.props.isSessionExpired){
      this.setState({open: this.props.isSessionExpired});
    }
  }

  render() {
    const help =
      <DialogContent style={styles.dialogContent}>
        <Box mb={1}>
          <h2>Your Session has expired</h2><br/>
          Click "reload page" to create a new session.
        </Box>
      </DialogContent>;

    return (
      <Dialog
        open={!!this.state.open}
        onClose={this.handleClose}
        style={styles.dialog}
        maxWidth="md"
        fullWidth={false}
        TransitionComponent={Transition}>
        {help}
        <DialogActions>
          <Button onClick={this.handleReload}  color="primary">
            Reload Page
          </Button>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = ({
  dialog: {
    maxHeight: 940,
    zIndex: 2500,
    fontSize: 14
  },
  dialogContent: {
    paddingBottom: 22,
    maxWidth: '450px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

function mapStateToProps(state){
  return {
    isSessionExpired: state.search.sessionExpired
  }
}

export default connect(mapStateToProps)(withStyles(styles)(SessionExpiredDialog));

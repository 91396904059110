import React from 'react';
import { connect } from "react-redux";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import Collapse from '@material-ui/core/Collapse';
import { action_filter_license} from '../../../Modules/redux/actions'
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from '@material-ui/core/IconButton';
import {ccIcon, saIcon, byIcon, otIcon, pdIcon} from "../../../lib/LicenseIcons"

const licenseDict = {
    "cc": "Creative Commons",
    "sa": "Share Alike",
    "by": "Attribution",
    "pd": "Public Domain",
    "ot": "others"
};

class LicenseFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    openDialog() {
        this.setState({
            open: true,
        });
    }

    closeDialog() {
        this.setState({
            open: false,
          });
    }

    handleClick(license) {
        const licenses = this.props.licenses.slice();
        const index = licenses.indexOf(license);
        if (index === -1) {
            licenses.push(license)
        } else {
            licenses.splice(index, 1);
        }

        this.props.dispatch(action_filter_license(Object.assign(licenses, {})));
    }

    isLicenseActive(license){
        if(!this.props.licenses)
            return false;

        const licenses = this.props.licenses;
        const index = licenses.indexOf(license);
        return index !== -1;
    }

    getLicenseListChar(i){
        if((i === this.props.licenses.length - 1) && (i > 0)){
            return " and"; 
        }else if (this.props.licenses.length === 1 || i === 0){
            return " "
        }else{
            return ", "
        }
    }

    generateLicenseString(){
        if (this.props.licenses.length === 0){
            return (
                <span style={{fontSize: "16px"}}>
                    No active filters. All images are currently shown.
                </span>
                )
        }else{
            return (
                <span style={{fontSize: "16px"}}>
                    Images with license properties
                    {
                        this.props.licenses.map((license, i) => {
                            return(
                                <span key={license} style={{fontSize: "16px"}}>
                                    {this.getLicenseListChar(i)}
                                    {' '}<span style={{color: 'red', fontSize: "16px"}}>{licenseDict[license]}</span>
                                </span>
                            )
                        })
                    }
                     {' '}are currently excluded
                 </span>
            )
        }

    }

    render() {
        const licenses = ["cc", "by", "sa", "pd", "ot"];

        const buttonClasses = new Map();

        for (let i = 0; i < licenses.length; i++) {
            const license = licenses[i];
            if(this.isLicenseActive(license))
                buttonClasses.set(license,  this.props.classes.svgActive);
            else
                buttonClasses.set(license, this.props.classes.svg);
        }
        
        return (
            <div className={this.props.classes.rootContainer}>
                
                <Collapse in={this.state.open}>
                    <IconButton
                          edge="end"
                          aria-label="Close license filter"
                          className={this.props.classes.closeButton}
                          onClick={() => this.closeDialog()}>
                          <ArrowDropUp style={{fontSize: "2em"}}/>
                    </IconButton>
                    <div className={this.props.classes.containerHeight}>
                        <div className={this.props.classes.licenseButtonContainer}>

                            <div className={this.props.classes.iconContainer}>
                                <IconButton 
                                onClick={(e) => this.handleClick("cc", e)}>
                                    <svg aria-hidden="true" focusable="false"
                                    role="img" xmlns="http://www.w3.org/2000/svg" 
                                    viewBox={ccIcon.viewBox} className={buttonClasses.get("cc")}>
                                    <path fill="currentColor" d={ccIcon.path}/></svg>
                                </IconButton>
                                <p className={this.props.classes.iconContainerText}>Creative Commons</p>
                            </div>

                            <div className={this.props.classes.iconContainer}>
                                <IconButton
                                onClick={(e) => this.handleClick("by", e)}>
                                    <svg aria-hidden="true" focusable="false"
                                    role="img" xmlns="http://www.w3.org/2000/svg" 
                                    viewBox={byIcon.viewBox} className={buttonClasses.get("by")}>
                                    <path fill="currentColor" d={byIcon.path}/></svg>
                                </IconButton>
                                <p className={this.props.classes.iconContainerText}>Attribution</p>
                            </div>
                            
                            <div className={this.props.classes.iconContainer}>
                                <IconButton
                                onClick={(e) => this.handleClick("sa", e)}>
                                    <svg aria-hidden="true" focusable="false"
                                    role="img" xmlns="http://www.w3.org/2000/svg" 
                                    viewBox={byIcon.viewBox} className={buttonClasses.get("sa")}>
                                    <path fill="currentColor" d={saIcon.path}/></svg>
                                </IconButton>
                                <p className={this.props.classes.iconContainerText}>ShareAlike</p>
                            </div>

                            
                            <div className={this.props.classes.iconContainer}>
                                <IconButton 
                                onClick={(e) => this.handleClick("pd", e)}>
                                    <svg aria-hidden="true" focusable="false"
                                    role="img" xmlns="http://www.w3.org/2000/svg" 
                                    viewBox={pdIcon.viewBox} className={buttonClasses.get("pd")}>
                                    <path fill="currentColor" d={pdIcon.path}/></svg>
                                </IconButton>
                                <p className={this.props.classes.iconContainerText}>Public Domain</p>
                            </div>

                            <div className={this.props.classes.iconContainer}>
                                <IconButton
                                onClick={(e) => this.handleClick("ot", e)}>
                                    <svg aria-hidden="true" focusable="false" 
                                    role="img" xmlns="http://www.w3.org/2000/svg" 
                                    viewBox={otIcon.viewBox} className={buttonClasses.get("ot")}>
                                        <path fill="currentColor" d={otIcon.path}/>
                                    </svg>
                                </IconButton>
                                <p className={this.props.classes.iconContainerText}>Others</p>
                            </div>


                        </div>
                        
                        <p style={{padding: "0px 10px 10px 10px"}}>
                            {this.generateLicenseString()}
                        </p>
                        </div>
                    
                </Collapse>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        licenses: state.filter.licenses
    }
}

const styles = ({
    rootContainer:{
        margin: '5px',
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 4px"
    },
    blockPicker: {
        marginTop: '5px',
        '#content div:first-child': {
            padding: "10px 7px 12px 18px"
        },
        backgroundColor: '#EEE !important'
    },
    colorPoint:{
        '& div:first-child': {
            display: "none"
        },
        '&:hover': {
            border: "3px solid black",
            '& div:first-child': {
                display: "table",
                borderRadius: "10px",
                lineHeight: "17px",
                color: "black",
                backgroundColor: "rgba(255,255,255,0.5)",
            }
        }
    },
    licenseButtonContainer:{
        display: "flex"
    },
    iconContainer:{
        textAlign:"center",
        width: "120px",
    }, 
    iconContainerText: {
        fontSize: "12px", 
    },
    svg:{
        width: '30px',
        height: '30px',
        '&:hover': {
            color: '#003a6a',
            // opacity: 0.6,
        }
    },
    svgActive: {
        height: '24px',
        width: '30px',
        marginTop: "3px",
        marginBottom: "3px",
        color: 'red',
        opacity: 0.6,
        '&:hover': {
            opacity: 0.9,
        }

    },
    containerHeight:{
        height: "180px"
    },
    closeButton:{
        padding: "0px",
        left: "90%"
    }
});

export default connect(mapStateToProps, null, null,{forwardRef : true})(withStyles(styles)(LicenseFilter));
